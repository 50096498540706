import React from 'react'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'

const PM = ({
    modalTitle,
    generateHeaderCloseIco,
    socket,
    toRoom,
    toWho,
    users,
    currUser,
    me,
    generateCreatedAt,
    userSuspended
}) => {

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    <ChatBody generateCreatedAt={generateCreatedAt} currUser={currUser} socket={socket} pm={true} room={toRoom} to={toWho} from={currUser} users={users} />
                </div>
            </div>
            <ChatFooter userSuspended={userSuspended} socket={socket} pm={true} to={toWho} room={toRoom} me={me}/>
        </div>
    )
}

export default PM