import React from 'react'
import Modal from './Modal'
import Player from './Player'
import GameBox from './GameBox'

const Titlebar = ({
    socket,
    currUser,
    gamers,
    shoutsCount,
    mmNew,
    isInMMNewQueue,
    statusHistory,
    users,
    maxPlayersNum,
    questBox,
    handleKickStatus,
    handleOpenSeasonsModal,
    me,
    isWinter,
    handleOpenEventsModal,
    menuActive,
    handleOpenNewsModal,
    handleOpenUsersModal,
    userSuspended
}) => {

    const [status, setStatus] = React.useState('')
    const [btnLabel, setBtnLabel] = React.useState('Multiplayer')
    const [showMPBtn, setShowMPBtn] = React.useState(true)
    const [MPtimer, setMPtimer] = React.useState(0)
    const [showModal, setShowModal] = React.useState(false)
    const [showMMModal, setShowMMModal] = React.useState(false)
    const [showMMNewModal, setShowMMNewModal] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const [showRadio, setShowRadio] = React.useState(false)
    const [showPracticeModal, setShowPracticeModal] = React.useState(false)

    currUser = localStorage.getItem('userName')

    React.useEffect(() => {
        const handleSetQuest = titleToSet => {
            setTitle(titleToSet)
        }

        socket.on('setQuest', handleSetQuest)

        return () => {
            socket.off('setQuest', handleSetQuest)
        }

    }, [socket, title])

    //prikaži sakrij MP botun sa servera
    React.useEffect(() => {

        const handleToggleMPButton = val => {
            setShowMPBtn(val)
        }
        socket.on('toggleMPButton', handleToggleMPButton)

        return () => {
            socket.off('toggleMPButton', handleToggleMPButton)
        }
    }, [socket, showMPBtn])

    React.useEffect(() => {
        const handleTimer = (mpCountdown) => {
            setMPtimer(mpCountdown)
        }
        socket.on('timer', handleTimer)

        return () => {
            socket.off('timer', handleTimer)
        }
    }, [socket, MPtimer])

    React.useEffect(() => {

        const handleResetTimer = () => {
            setMPtimer(0)
        }
        socket.on('resetTimer', handleResetTimer)

        return () => {
            socket.off('resetTimer', handleResetTimer)
        }
    }, [socket, MPtimer])

    React.useEffect(() => {
        const gamersNum = Object.keys(gamers).length
        if (gamersNum === 0) {
            setBtnLabel('Multiplayer')
            setMPtimer(0)
        } else if (gamers[currUser] !== undefined) {
            setBtnLabel('Leave')
        } else if (gamers[currUser] === undefined && gamersNum !== 0 && gamersNum < maxPlayersNum) {
            setBtnLabel('Join')
        } else if (gamers[currUser] === undefined && gamersNum === maxPlayersNum) {
            setShowMPBtn(false)
        }
    }, [gamers])

    React.useEffect(() => {

        const handleStatusUpdate = newStatus => {
            setStatus(newStatus)
        }
        socket.on('statusUpdate', handleStatusUpdate)

        return () => {
            socket.off('statusUpdate', handleStatusUpdate)
        }
        
    }, [socket, status])

    const handleGameBtnClick = () => {
        if (gamers[currUser] !== undefined) {
            socket.emit('leaveMP', currUser)
            return
        }
        socket.emit('game', currUser)
    }

    const handleCloseMMNewModal = () => {
        setShowMMNewModal(false)
    }

    const handleOpenMMNewModal = () => {
        setShowMMNewModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleOpenModal = () => {
        setShowModal(true)
    }

    const handleMMBtnClick = () => {
        setShowMMModal(true)
    }

    const handleCloseMMModal = () => {
        setShowMMModal(false)
    }

    const handleShowRadio = () => {
        setShowRadio(!showRadio)
    }

    const handleOpenPracticeModal = () => {
        setShowPracticeModal(true)
    }

    const handleClosePracticeModal = () => {
        setShowPracticeModal(false)
    }

    const generateTitleBar = () => {
        return (
            <div>
                {
                    menuActive && <div className="main-header">
                        <div className="statusbar">

                            {status.length < 60
                                ?
                                <p>{status}</p>
                                :
                                <p className="text-carousel-titlebar">
                                    <span
                                        style={{ animation: 'marquee 15s linear infinite' }}>
                                        {status}
                                    </span>
                                </p>
                            }

                            <img onClick={handleOpenModal} className="margin-left pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVR4nL2UQQ6CMBBFwQuA1xBX7gAPgN7Dm6BewIO49zAuFNQT6O6ZSbqo0NJSEn/SpMk0L+38+Y2ifwqYARmwUUv2cQhoDhyBlr4a4ACkvrA18MKtJ1D6wD746w0UNlg6cLMtcLbUHkBiAkrPbBIzTgP1vcnNdgKw+XEfWA4c9gGKMh0oMzYVWOnAynF41WnRzgXMHMArcNGW9KyrhQ6MgRvhuvciqeIUqto22BKnsWqNg62gZUD0ciNMgxYqTj43ywdhGjSROFncFANq6zMdYHF/oX2wsh//wU7RF+iSwHOaSMkPAAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenPracticeModal} className="margin-left pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVR4nM3UT2jPcRzH8d9YzUkph6VFu4h2kFqUdjXKLkrKwUHJYaTmIH9u1JpCltOwRsRBKYtcth3ERQ6KGimRDTlszVpi1kPvef/at19r7Y/iWd/en/fn+/2+Pp/X5/t+f0ulfwn2Y9ffFLyOpqWKrEEzzqEfyxYrVIWrKdKB3Vi+lJ214FIhr8Ym7MGhiusA6oovH8c3fMRdtOEGTuEoevP+XAyjJsTWYsr8GMUjfMDNQizTGII7M/malgZxDWOYwC28wjo8Qz1eYksh/kqNvSHYmklY6s5VN2Moj2Aj3uM+Js3NiRA8n8mdPLcQ/lF4aDLnLmIA23Ab6yticCUE700P6cFhXMBWMzTloqfxGM/xGW/wpRCDvhB8kUlYDH6mxTJvc+51Lv4QnbPE4F0pD1+WTxeO4FjUINqzoJvTQdTbE5zB04oYfA+RT9PDP9sewWXsS6s70mq8NB/6yh0RJbMQprK8erM9w9lJrCp3ygocxAOMzyIwlh/kLLZj5UL7eDUasAG1i/4h/Jf8Br1Wd73hG/gyAAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenSeasonsModal} className="margin-left pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nO3UPUpDQRTF8VcFF6CluIQsIQRErLTQfQiJIsHGHbgObUTIFoKlhZgtxCJVrBT1JwMTeIT7PnyleODCcOecP+/dYaYo/oUebnDXUMnTq5wYdrCPsfYa5cx2BJzqrmkEfMA3zoPAc65NXeTMfQS8yqYDvJZCK2zleiv1FzjM60kE3MMH5piVgp84wjG+Sv1Z9r5jt+pgJtmcfqNJa89l7d3A2caX1AGvW1009PFYA0t7/VawtdJcsAxgy8qZNQnDADjsChvgKQCm3qALcF4zw5cuwFPcBo9C6p38Glj8Wf0AJvhzO8ynCGUAAAAASUVORK5CYII=" />
                            <img onClick={handleOpenEventsModal} className="margin-left pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8ElEQVR4nKXUv0pDMRTH8evaXUFcXHwAB7U4dak+QRdHB1fFTfEB/FO6+wJ26SwuipMPIDo5Obg4uAiiIHwkkGJQe2+89weBkPPLl5xzkhRFhbCKpaKpcI5RU8gcPvGBmSagA9/arQuZwkMCuqsL6vit5bING9iJaRzhFEPc/wG6xVn0HMY92+gF0EBzHaeneqsBeMfmzxQX8fgPyBNWJtVrGtcZkBvMVnWrnQFq57R9PwO0lwO6ygBdVkFasRNjvcZ7EkaYjxU8rTLQemIORV9IYvO4SOJrZaA+XrAV3toETw/POCkDdXO+i+AJ3nTxC9M6aJa+Ow0qAAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenUsersModal} className="margin-left pointer hidden m1300-show" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYklEQVR4nNXTv0vWURTH8UciISgMCx1MFJoEQRpylUBpsT/CwSRo8w9waZE2F3cXoaAhNAIhXPwPbHDqBxGiZgqGiuIrbpwvXK/f5+kZ6z3de87nfL7ne++5jcZ/A+5jHkfYwHAL7Rg2QzuP26XgCc5d5iM6asxu4kehXS1Fa67yAZ3owiM8DO09fC60v0rDT4VgD93R+X4Wf40RDOE0i++Wht8Lw0XcwEFN519wDW+z2E5puFMUzeKB5gzGZVQs5Ga3cOYyL3GnJp44ie6XYr+PvtwwJeewjp8heh+58jYTe5Fbif2rVrOYTBPPY/+mxnA5clux364mIDcaxbus6BiTcVaHWTytBzBe86G1P78eF3BRI/iGHjzNYml9t2YOK1YaMXMlXzEV4/Esi6f1dUyHpuSw1Xn240V6BVnBUcT6mxbWgZkYj2akVzLTrlnvX8wqkqanHcPHbZhVTLTV5T/Fb03Weaycmdr0AAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenNewsModal} className="margin-left pointer hidden m1200-show" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVR4nK2UsYoCMRCGFx/gnkGu8QEsLLW+6jobi6t8Ae3s5MBeq0VbS0VLLS1F7LQT5AoLFzutv2NwhCGsa4L7ww/JJHyZIZNEUd4CisAa2AT4JwtYJly/uQOBDrACSkAPKLjAM1DR+TOPDDAGZkBXY1UXeAWGuvGZtw4wVovqLvCWNzBE3sCjaQ0Zi3Ymdg4F9oGmeqCxtonN3wH2NdZ6B3hMKXlvYkkosGV6TkoVfWX0YfwKuDCbZCwa61wyfeik2SYm64NUkQVcpgDLzpPdO5kmsvDpWfLUF1gA/vBTwxzYSAVG99NqwMUDOHnxNO9AhX4A36bnXMtXZVsozbeQn/1xMZnAf0p/5sSm/k/CAAAAAElFTkSuQmCC" />
                            <div className="relative">
                                {shoutsCount !== 0 ? <span className="shout-number">{shoutsCount}</span> : null}
                                <img onClick={handleMMBtnClick} className="botimgttlbr" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO3Uz0oDMRDH8bl40r6BHtqLf87qSxYrvoIHERE9tIX6Gtp3EAUPtUeFr8TmILjZlmxmN9H5wLJLYJL9ZbIrYkxZgD3gFnj31x2wLwWGeOM3N7YrpWDViZAbKQWroxSykFIArzVBXqQUwHVNkCspBTAAnitCuLGBlAToA5c/QrjnvpQKT0qHBVEEHAEj4AlYkt7Sz30GHGoE2AIugE/a8wGcu7VThnigO7MkYXwnujZK8U20eZzqjtlBkyDuw87FsEmQOfl4bBJE4xcba9EkSFZEOcgYOAG2/X2iVINmkHGg7j5xjXqQ40DdaeKab6leukovMFcvcU1QqiCtdkQzyCTwUtPENepB8H8ct5s7/j5VqlEP0imxIJkR60hmxDqSGfmzHakSO1ns+DoSK3aB2PF1LAgbso403LCNWUe80MZk25G2yL8PYoxE+wJmA95jdYQihQAAAABJRU5ErkJggg==" />
                            </div>

                            {showModal && <Modal
                                currUser={currUser}
                                handleCloseModal={handleCloseModal}
                                socket={socket}
                                title='Povijest statusa'
                                statusHistory={statusHistory}
                                type='statusHistory'
                                handleKickStatus={handleKickStatus}
                            />}
                            {showMMModal && <Modal
                                currUser={currUser}
                                handleCloseModal={handleCloseMMModal}
                                socket={socket}
                                title=''
                                type='matchmakingQueue'
                                users={users}
                                me={me}
                                userSuspended={userSuspended}
                            />}
                            {showMMNewModal && <Modal
                                mmNew={mmNew}
                                currUser={currUser}
                                handleCloseModal={handleCloseMMNewModal}
                                socket={socket}
                                title='1-1 matchmaking'
                                type={'MMNew'}
                                me={me}
                            />}
                            {showPracticeModal && <Modal
                                handleCloseModal={handleClosePracticeModal}
                                socket={socket}
                                title='Vježbaj tipkanje'
                                type={'practice'}
                                questBox={questBox}
                            />}
                            <div className={showModal || showMMModal || showPracticeModal || showMMNewModal ? 'overlay-app' : ''}></div>
                        </div>
                        <div className="btns-row margin-top-2">
                            <button onClick={handleOpenMMNewModal} className={`content-button ${isWinter && 'christmass-btn margin-top-5'}`}>{`Matchmaking (${mmNew})`}</button>
                            {showMPBtn && !userSuspended && users.length !== 1 ? <button onClick={handleGameBtnClick} className={`content-button game-bg margin-left ${isWinter && 'christmass-btn margin-top-5'}`}>{`${btnLabel} ${MPtimer === 0 ? '' : MPtimer}`}</button> : null}
                        </div>
                    </div>
                }

                <div className="main-header main-header-alt">
                    {
                        menuActive && <div className="search-bar-alt" style={{
                            display: !showRadio ? 'none' : ''
                        }}>
                            <Player socket={socket} />
                        </div>
                    }

                    {
                        menuActive && <div className="statusbar statusbar-alt">
                            {status.length < 60
                                ?
                                <p className="margin-top-5 m780-hide">{status}</p>
                                :
                                <p className="margin-top-5 m780-hide text-carousel-titlebar">
                                    <span
                                        style={{ animation: 'marquee 15s linear infinite' }}>
                                        {status}
                                    </span>
                                </p>
                            }

                            <img onClick={handleOpenModal} className="titlebar-ico" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVR4nL2UQQ6CMBBFwQuA1xBX7gAPgN7Dm6BewIO49zAuFNQT6O6ZSbqo0NJSEn/SpMk0L+38+Y2ifwqYARmwUUv2cQhoDhyBlr4a4ACkvrA18MKtJ1D6wD746w0UNlg6cLMtcLbUHkBiAkrPbBIzTgP1vcnNdgKw+XEfWA4c9gGKMh0oMzYVWOnAynF41WnRzgXMHMArcNGW9KyrhQ6MgRvhuvciqeIUqto22BKnsWqNg62gZUD0ciNMgxYqTj43ywdhGjSROFncFANq6zMdYHF/oX2wsh//wU7RF+iSwHOaSMkPAAAAAElFTkSuQmCC" />
                            <img onClick={handleShowRadio} className="radijo-btn titlebar-ico" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9UlEQVR4nNWUSwrCMBRFO9AFaFXqyA4ULC06cDeCA8eKnwWpuB1x4Ab8DBVEV6BHAq/wCHFkBL0Q6Os7uSS3aYLgLwTUgd6Ho56bzYEHn+sBzIzhHX+6GUOvCqwcEivb2JFVw2JS3dcrPANtC64CO8VsgLLFZMBVrzBXX0EtoCDPXek/8x0ARcMofugyLElzIvVCTTgBe1WvhRlJXXEZhtIcSb1SBgfgqOqlMGOpyy7DoZrQVFtOZbtmdORdwTCKH7gMTbCZFXgIbBVjPlDNYhLg8u7YpBbccByb2GHYcx0bLwq+8etNPV4OkzyHyMP1Felsf1cvQUXYWwyIjKIAAAAASUVORK5CYII=" />
                            <img onClick={handleOpenPracticeModal} className="titlebar-ico" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVR4nM3UT2jPcRzH8d9YzUkph6VFu4h2kFqUdjXKLkrKwUHJYaTmIH9u1JpCltOwRsRBKYtcth3ERQ6KGimRDTlszVpi1kPvef/at19r7Y/iWd/en/fn+/2+Pp/X5/t+f0ulfwn2Y9ffFLyOpqWKrEEzzqEfyxYrVIWrKdKB3Vi+lJ214FIhr8Ym7MGhiusA6oovH8c3fMRdtOEGTuEoevP+XAyjJsTWYsr8GMUjfMDNQizTGII7M/malgZxDWOYwC28wjo8Qz1eYksh/kqNvSHYmklY6s5VN2Moj2Aj3uM+Js3NiRA8n8mdPLcQ/lF4aDLnLmIA23Ab6yticCUE700P6cFhXMBWMzTloqfxGM/xGW/wpRCDvhB8kUlYDH6mxTJvc+51Lv4QnbPE4F0pD1+WTxeO4FjUINqzoJvTQdTbE5zB04oYfA+RT9PDP9sewWXsS6s70mq8NB/6yh0RJbMQprK8erM9w9lJrCp3ygocxAOMzyIwlh/kLLZj5UL7eDUasAG1i/4h/Jf8Br1Wd73hG/gyAAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenSeasonsModal} className="titlebar-ico" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nO3UPUpDQRTF8VcFF6CluIQsIQRErLTQfQiJIsHGHbgObUTIFoKlhZgtxCJVrBT1JwMTeIT7PnyleODCcOecP+/dYaYo/oUebnDXUMnTq5wYdrCPsfYa5cx2BJzqrmkEfMA3zoPAc65NXeTMfQS8yqYDvJZCK2zleiv1FzjM60kE3MMH5piVgp84wjG+Sv1Z9r5jt+pgJtmcfqNJa89l7d3A2caX1AGvW1009PFYA0t7/VawtdJcsAxgy8qZNQnDADjsChvgKQCm3qALcF4zw5cuwFPcBo9C6p38Glj8Wf0AJvhzO8ynCGUAAAAASUVORK5CYII=" />
                            <img onClick={handleOpenEventsModal} className="titlebar-ico" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8ElEQVR4nKXUv0pDMRTH8evaXUFcXHwAB7U4dak+QRdHB1fFTfEB/FO6+wJ26SwuipMPIDo5Obg4uAiiIHwkkGJQe2+89weBkPPLl5xzkhRFhbCKpaKpcI5RU8gcPvGBmSagA9/arQuZwkMCuqsL6vit5bING9iJaRzhFEPc/wG6xVn0HMY92+gF0EBzHaeneqsBeMfmzxQX8fgPyBNWJtVrGtcZkBvMVnWrnQFq57R9PwO0lwO6ygBdVkFasRNjvcZ7EkaYjxU8rTLQemIORV9IYvO4SOJrZaA+XrAV3toETw/POCkDdXO+i+AJ3nTxC9M6aJa+Ow0qAAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenUsersModal} className="titlebar-ico hidden m1300-show" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYklEQVR4nNXTv0vWURTH8UciISgMCx1MFJoEQRpylUBpsT/CwSRo8w9waZE2F3cXoaAhNAIhXPwPbHDqBxGiZgqGiuIrbpwvXK/f5+kZ6z3de87nfL7ne++5jcZ/A+5jHkfYwHAL7Rg2QzuP26XgCc5d5iM6asxu4kehXS1Fa67yAZ3owiM8DO09fC60v0rDT4VgD93R+X4Wf40RDOE0i++Wht8Lw0XcwEFN519wDW+z2E5puFMUzeKB5gzGZVQs5Ga3cOYyL3GnJp44ie6XYr+PvtwwJeewjp8heh+58jYTe5Fbif2rVrOYTBPPY/+mxnA5clux364mIDcaxbus6BiTcVaHWTytBzBe86G1P78eF3BRI/iGHjzNYml9t2YOK1YaMXMlXzEV4/Esi6f1dUyHpuSw1Xn240V6BVnBUcT6mxbWgZkYj2akVzLTrlnvX8wqkqanHcPHbZhVTLTV5T/Fb03Weaycmdr0AAAAAElFTkSuQmCC" />
                            <img onClick={handleOpenNewsModal} className="titlebar-ico hidden m1200-show" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVR4nK2UsYoCMRCGFx/gnkGu8QEsLLW+6jobi6t8Ae3s5MBeq0VbS0VLLS1F7LQT5AoLFzutv2NwhCGsa4L7ww/JJHyZIZNEUd4CisAa2AT4JwtYJly/uQOBDrACSkAPKLjAM1DR+TOPDDAGZkBXY1UXeAWGuvGZtw4wVovqLvCWNzBE3sCjaQ0Zi3Ymdg4F9oGmeqCxtonN3wH2NdZ6B3hMKXlvYkkosGV6TkoVfWX0YfwKuDCbZCwa61wyfeik2SYm64NUkQVcpgDLzpPdO5kmsvDpWfLUF1gA/vBTwxzYSAVG99NqwMUDOHnxNO9AhX4A36bnXMtXZVsozbeQn/1xMZnAf0p/5sSm/k/CAAAAAElFTkSuQmCC" />
                            <div className="relative shoutbox-ico-wrap">
                                {shoutsCount !== 0 ? <span className="shout-number">{shoutsCount}</span> : null}
                                <img onClick={handleMMBtnClick} className="botimgttlbr titlebar-ico" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO3Uz0oDMRDH8bl40r6BHtqLf87qSxYrvoIHERE9tIX6Gtp3EAUPtUeFr8TmILjZlmxmN9H5wLJLYJL9ZbIrYkxZgD3gFnj31x2wLwWGeOM3N7YrpWDViZAbKQWroxSykFIArzVBXqQUwHVNkCspBTAAnitCuLGBlAToA5c/QrjnvpQKT0qHBVEEHAEj4AlYkt7Sz30GHGoE2AIugE/a8wGcu7VThnigO7MkYXwnujZK8U20eZzqjtlBkyDuw87FsEmQOfl4bBJE4xcba9EkSFZEOcgYOAG2/X2iVINmkHGg7j5xjXqQ40DdaeKab6leukovMFcvcU1QqiCtdkQzyCTwUtPENepB8H8ct5s7/j5VqlEP0imxIJkR60hmxDqSGfmzHakSO1ns+DoSK3aB2PF1LAgbso403LCNWUe80MZk25G2yL8PYoxE+wJmA95jdYQihQAAAABJRU5ErkJggg==" />
                            </div>



                            {showModal && <Modal
                                currUser={currUser}
                                handleCloseModal={handleCloseModal}
                                socket={socket}
                                title='Povijest statusa'
                                statusHistory={statusHistory}
                                type='statusHistory'
                                handleKickStatus={handleKickStatus}
                            />}
                            {showMMModal && <Modal
                                currUser={currUser}
                                handleCloseModal={handleCloseMMModal}
                                socket={socket}
                                title=''
                                type='matchmakingQueue'
                                me={me}
                            />}
                            {showMMNewModal && <Modal
                                mmNew={mmNew}
                                currUser={currUser}
                                handleCloseModal={handleCloseMMNewModal}
                                socket={socket}
                                title=''
                                type={'MMNew'}
                                me={me}
                            />}
                            {showPracticeModal && <Modal
                                handleCloseModal={handleClosePracticeModal}
                                socket={socket}
                                title='Vježbaj tipkanje'
                                type={'practice'}
                                questBox={questBox}
                            />}
                            <div className={showModal || showMMModal || showPracticeModal || showMMNewModal ? 'overlay-app' : ''}></div>
                        </div>
                    }

                    <div className="statusbar statusbar-alt2 hidden m780-show">
                        {status.length < 60
                            ?
                            <p className="margin-top-5 margin-bottom-5">{status}</p>
                            :
                            <p className="margin-top-5 text-carousel-titlebar margin-bottom-5">
                                <span
                                    style={{ animation: 'marquee 15s linear infinite' }}>
                                    {status}
                                </span>
                            </p>
                        }
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div>
            {title.length === 0 && generateTitleBar()}
            {title.length !== 0 && (
                <div className="main-header">
                    <GameBox imgSrc="go-white.png" users={users} socket={socket} gamers={gamers} title={title} sub="Multiplayer završava za:" btnText="empty" />
                </div>
            )}
        </div>
    )
}

export default Titlebar