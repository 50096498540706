import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const NotifyQueue = ({
    modalTitle,
    generateHeaderCloseIco,
    currUser,
    joinRoom,
    setNotification,
    setType,
    notification,
    socket
}) => {

    const [notifyQueue, setNotifyQueue] = React.useState([])
    const [PMQueue, setPMQueue] = React.useState([])

    const getNotifyQueue = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/user-name/${currUser}`,
                {
                    attributes: 'notifyQueue'
                }
            )
            if (response.data.status === 1) {
                setNotifyQueue(response.data.user.notifyQueue.reverse())
            }
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        getNotifyQueue()
    }, [currUser])

    React.useEffect(() => {


        const handleRefreshUserNotifyQueue = () => {
            getNotifyQueue()
        }

        socket.on('refreshUserNotifyQueue', handleRefreshUserNotifyQueue)

        return () => {
            socket.off('refreshUserNotifyQueue', handleRefreshUserNotifyQueue)
        }

    }, [socket])

    const markAllAsRed = async () => {
        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/mark-notify-as-red-all/${localStorage.getItem('userName')}`)

            if (response.data.status === 1) {
                getNotifyQueue()
                setNotification(0)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const markNotifyAsRed = async (id) => {
        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/mark-notify-as-red/${localStorage.getItem('userName')}`,
                {
                    id
                }
            )

            if (response.data.status === 1) {
                getNotifyQueue()
                setNotification(notification !== 0 ? notification - 1 : 0)
            }
        } catch (e) {
            console.log(e)
            getNotifyQueue()
        }
    }

    const renderNotifyQueue = () => {
        return notifyQueue.map((notify) => {

            let notifyTitle = ''

            if (notify.type === 'mm') {
                notifyTitle = '1-1 matchmaking:'
            } else if (notify.type === 'shout') {
                notifyTitle = 'Shoutbox:'
            } else if (notify.type === 'notify') {
                notifyTitle = 'nethr.chat wars'
            } else if (notify.type === 'wars') {
                notifyTitle = 'Kraj sezone'
            } else if (notify.type === 'intro') {
                notifyTitle = 'Dobro nam došli!'
            } else if (notify.type === 'system') {
                notifyTitle = 'Sistemska notifikacija'
            } else if (notify.type === 'event') {
                notifyTitle = 'Offline event!'
            } else if (notify.type === 'event') {
                notifyTitle = 'Nova korisnička vijest'
            }

            const generateNotifyGameBg = () => {
                if (notify.red === true) {
                    return null
                } else if (notify.type === 'wars' || notify.type === 'intro' || notify.type === 'event' || notify.type === 'system') {
                    return 'game-bg'
                }
            }


            const handleNotificationClick = (notify) => {
                if (notify.type === 'wars') {
                    markNotifyAsRed(notify.id)
                    setType('seasons')
                } else if (notify.type === 'intro') {
                    markNotifyAsRed(notify.id)
                    setType('uvjetiKoristenja')
                } else if (notify.type === 'system') {
                    markNotifyAsRed(notify.id)
                } else if (notify.type === 'event') {
                    markNotifyAsRed(notify.id)
                    setType('events')
                } else if (notify.type === 'news') {
                    markNotifyAsRed(notify.id)
                    setType('news')
                } else {
                    markNotifyAsRed(notify.id)
                    joinRoom(notify)
                }
            }

            return (
                <div className="message__chats margin-top" key={notify.room}>
                    <div className="pmqueue-single-wrap">
                        <img className="avatar-img" src={notify.fromAvatar}></img>
                        <p><span className="pmqueue-single-wrap__span">{notifyTitle}</span> {notify.from}</p>
                    </div>
                    <div
                        onClick={function () { handleNotificationClick(notify) }}
                        className={
                            `${notify.red === true ? 'message__recipient' : 'message__sender'} 
                            pointer margin-bottom 
                            ${generateNotifyGameBg()}
                            `}>
                        <p>{notify.text}</p>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    <button onClick={markAllAsRed} className="content-button absolute-bottom-right">Označi sve kao pročitano</button>
                    {renderNotifyQueue()}
                </div>

            </div>
        </div>
    )
}

export default NotifyQueue