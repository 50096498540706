import React from 'react'
import PayPalBtn from './PayPalBtn'

const Donation = ({
    modalTitle,
    generateHeaderCloseIco,
    donators,
    socket
}) => {

    const [donationID, setDonationID] = React.useState(4)

    const renderDonation = () => {
        return (
            <div className="w100 margin-top">
                <div className="row w100">
                    <img src="/ivanko.jpg" className="avatar-img my-img margin-right" />
                    <div className="column">
                        <p><i>Ako si pronašao ljubav, prijateljstvo, uspjeo nešto prodati, naučio nešto novo<br/> ili se samo dobro zabavio - počasti! :) (uplate su anonimne, uneseni podaci su obavezni i služe PayPal-u za komuniciranje sa izdavačima kreditnih kartica - ali ja te podatke ne vidim!)</i></p>
                        <a href="https://ivankoperisic.from.hr/" target="_blank" className="my-text">Ivanko Perišić</a>
                    </div>
                </div>
                <div className="row margin-top donations-main-wrap">
                    <div className="left-col">
                        <div className="row middle">
                            <input onChange={handleDonationIDChange} checked={donationID === 1} type="radio" id="one" value="1" name="donation-type" className="donation-input" />
                            <label className="oneline" htmlFor="one"> Kava (1€)</label>
                        </div>
                        <div className="row middle">
                            <input onChange={handleDonationIDChange} checked={donationID === 2} type="radio" id="two" value="2" name="donation-type" className="donation-input" />
                            <label className="oneline" htmlFor="two">Pivo (2€)</label>
                        </div>
                        <div className="row middle">
                            <input onChange={handleDonationIDChange} checked={donationID === 3} type="radio" id="three" value="3" name="donation-type" className="donation-input" />
                            <label className="oneline" htmlFor="three">McDonald's (3€)</label>
                        </div>
                        <div className="row middle">
                            <input onChange={handleDonationIDChange} checked={donationID === 4} type="radio" id="four" value="4" name="donation-type" className="donation-input" />
                            <label className="oneline" htmlFor="four">CBD joint (5€)</label>
                        </div>
                        <div className="row middle">
                            <input onChange={handleDonationIDChange} checked={donationID === 5} type="radio" id="five" value="5" name="donation-type" className="donation-input" />
                            <label className="oneline" htmlFor="five">Jumbo pizza (10€)</label>
                        </div>

                        <div className="column margin-top">
                            <PayPalBtn
                                socket={socket}
                            />
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="donators-box">
                            <div className="my-friends">Moji prijatelji</div>
                            {donators && <div className="donators">
                                <ol>
                                    {donators.map(d => {
                                        return <li>{`${d.donator} - ${d.amount}€`}</li>
                                    })}
                                </ol>
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const handleDonationIDChange = (e) => {
        setDonationID(Number(e.target.value))
        socket.emit('updateDonationID', Number(e.target.value))
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderDonation()}
                </div>

            </div>
        </div>
    )
}

export default Donation