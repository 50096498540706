import React from 'react'
import Player from './Player'
import Modal from './Modal'
import axios from 'axios'

const {
  REACT_APP_DATABASE_BASEURL_DEV,
  REACT_APP_DATABASE_BASEURL,
  REACT_APP_NODE_ENV
} = process.env;

const Header = ({
  socket,
  avatar,
  newsList,
  toggleUsers,
  showUsers,
  currUser,
  users,
  statusHistory,
  handleKickUser,
  banners,
  questBox,
  me,
  isWinter,
  currEvent,
  formatEventDate,
  getNews,
  toggleMenu,
  handleOpenNewsModal,
  handleOpenUsersModal,
  handleStartOpeningSingleUserModal,
  menuActive,
  generateCreatedAt,
  userSuspended
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const [messagesNum, setMessagesNum] = React.useState(0)
  const [notification, setNotification] = React.useState(0)
  const [showAdminModal, setShowAdminModal] = React.useState(false)
  const [showNotificationsModal, setShowNotificationsModal] = React.useState(false)

  currUser = localStorage.getItem('userName')

  const handleMyProfileClick = () => {
    handleStartOpeningSingleUserModal(currUser)
  }

  //ažurira broj notifikacija i poruka prilikom refresha
  const getNotifyAndPmNumbers = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_ENV === 'development' ?
          REACT_APP_DATABASE_BASEURL_DEV :
          REACT_APP_DATABASE_BASEURL}/user-name/${currUser}`,
        {
          attributes: ['notifyQueue', 'pmQueue']
        }
      )
      if (response.data.status === 1) {
        setNotification(response.data.user.notifyQueue.filter(notify => notify.red === false).length)
        setMessagesNum(response.data.user.pmQueue.filter(pms => pms.red === false).length)
      }
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    getNotifyAndPmNumbers()
  }, [currUser])

  React.useEffect(() => {
    //ažurira broj notifikacija i poruka pushom sa servera

    const handleSetMessagesNum = (pmMessagesNum, currUserName) => {
      if (currUser === currUserName) {
        setMessagesNum(pmMessagesNum)
      }
    }

    const handleRefreshPMNotifications = () => {
      setMessagesNum(messagesNum + 1)
    }

    const handleSetNotification = (notificationsNum, currUserName) => {
      if (currUser === currUserName) {
        setNotification(notificationsNum)
      }
    }

    const handleRefreshNotifications = () => {
      setNotification(notification + 1)
    }

    socket.on("setMessagesNum", handleSetMessagesNum)
    socket.on("refreshPMNotifications", handleRefreshPMNotifications)
    socket.on("setNotification", handleSetNotification)
    socket.on("refreshNotifications", handleRefreshNotifications)

    return () => {
      socket.off("setMessagesNum", handleSetMessagesNum)
      socket.off("refreshPMNotifications", handleRefreshPMNotifications)
      socket.off("setNotification", handleSetNotification)
      socket.off("refreshNotifications", handleRefreshNotifications)
    }

  }, [socket, messagesNum, notification])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleOpenNotificationsModal = () => {
    setShowNotificationsModal(true)
  }

  const handleCloseNotificationsModal = () => {
    setShowNotificationsModal(false)
  }

  const handleOpenAdminModal = () => {
    setShowAdminModal(true)
  }

  const handleCloseAdminModal = () => {
    setShowAdminModal(false)
  }

  const handleToggleUsers = () => {
    toggleUsers()
  }

  const handleLeaveChat = () => {
    handleKickUser(currUser)
  }

  return (
    <div className="header">

      <div className="menu-circle">
        <img className="logo" src={isWinter ? 'logo-christmass.png' : 'logo.png'} />
        <img className="logo-alt" src={isWinter ? 'logo-alt-christmass.png' : 'logo-alt.png'} />
      </div>



      <div className="search-bar">
        <Player userSuspended={userSuspended} socket={socket} />
      </div>


      <div className="header-profile">
        <div className="header-menu margin-top-5">
          <p onClick={handleToggleUsers} className="menu-link is-active link-visible">{!showUsers && `Online (${users.length})`}</p>
          <p onClick={handleOpenUsersModal} className="menu-link is-active m1300-hide">Članovi</p>
          <p onClick={handleOpenNewsModal} className="menu-link is-active m1200-hide">Objave</p>
        </div>
        <img onClick={toggleMenu} className="pointer margin-top-5 margin-right hidden margin-left m780-show" src={`${menuActive ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAx0lEQVR4nO3Vu05CQRCA4X0aQgtiARIK0UgCvv8T2HGJBlCrz2y45CCXAOfW7F9tMdl/ZzKzE0IikagKNDG4If4B7bzSBj6wwvCK+BZmWKCTRzzA0oYof74Q+7gVRn4wulscwRO+theu8RJOlzdmGvnFeygCh/KYzfifdF64dAd6+MzIJ6VLz2T+nXlIPL+FMkE3I9xnX6r0RMmrEauj1I47u/zmcjzLr1XMcD8jXZ35QNqFytX1ZbpvSUxzL4na1mIikQg38Adi/+ESgabdTwAAAABJRU5ErkJggg==" : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAK0lEQVR4nO3RMQEAAAyDsPo3zUSUr4sAHpI9yKIHXw97Cnbw9bCnYAcz5wAwO94wV83isgAAAABJRU5ErkJggg=="}`} />
        {currUser === 'TheCreator' ? <img onClick={handleOpenAdminModal} className="pointer margin-top-5 margin-left margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIElEQVR4nKWVQU4CQRBFZ4EZd4RLAMqSC5hADETGOxlWuPMmOi4kUfeQEEy8huAN5JlOKqFoqqYz8JNZdPfU65ruqj9ZlhDQAvryNLNzBTyx1+wUwBjI1fhdAd/UfA6MUrCpBH4B18AtsFXADTAAroC1zD14sCGH2kXjqrWBBWwAC+prBVx4WXYTmcX6A9pVZzgygp6BCVAApbF+Y9VZKI2P6AKCXoxNY+hGKiEwWpkUrKc7Axgy9dRPAQsDeJ8CNkMHAHNJX6s0gK/ROz8SOztqTedSSvnMwoAdX0oE7J1QNl0PlkvL1dU3cOmZgtYukZnWOGUOS6Aj5vBrmENH3iHEuGco0GASDTX+VEBtX6H3h5UwZwNtsI+1AQaw1i/gHz6Vko9SaabaAAAAAElFTkSuQmCC"></img> : null}
        <div className="min-width-20 notification margin-right margin-left" onClick={handleOpenModal}>
          {messagesNum !== 0 && <span className="notification-number">{messagesNum}</span>}
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAx0lEQVR4nOXUQUqDMRCG4TmS0OIBSkXoQndd1lXX3kC8grjwFgqihyi0V1A8QMWdtEWfEkjhX8S/arMo9INZZJJ5k/kSErH3QqdmhLr6SsAxVhVgnxgm4A1O8bEDbI4e7jYt3+MYb/+AvWT/ntKg6eEER5j+ATbJNbNNIgq7dfH4C9hDqasoLHxHH7ctsOT7Scn3+KFggREu01No5L9xjQssS4XRcopUfIVzvOY4y8A0V1TYrnR7gxzP2xaHyorDBHaqfl97rzUKusbim005EQAAAABJRU5ErkJggg==" />
        </div>
        <div className="notification margin-left" onClick={handleOpenNotificationsModal}>
          {notification !== 0 && <span className="notification-number">{notification}</span>}
          <svg viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell">
            <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
          </svg>
        </div>
        <div className="avatar-img-wrap">
          <span onClick={handleLeaveChat} className="notification-number logout-ico">x</span>
          {avatar && <img onClick={handleMyProfileClick} className={`avatar-img ${avatar.includes("media/avatars") ? '' : 'coverimg'}`} style={{ marginLeft: '20px' }} src={avatar}></img>}
        </div>
      </div>
      {showModal && <Modal
        currUser={currUser}
        handleCloseModal={handleCloseModal}
        socket={socket}
        title='Privatni razgovori'
        users={users}
        type="pmqueue"
        avatar={avatar}
        setMessagesNum={setMessagesNum}
        messagesNum={messagesNum}
        me={me}
        generateCreatedAt={generateCreatedAt}
        userSuspended={userSuspended}
      />}
      {showNotificationsModal && <Modal
        handleCloseModal={handleCloseNotificationsModal}
        title="Notifikacije"
        type="notifications"
        socket={socket}
        currUser={currUser}
        setNotification={setNotification}
        notification={notification}
        users={users}
        me={me}
        currEvent={currEvent}
        formatEventDate={formatEventDate}
        newsList={newsList}
        getNews={getNews}
        generateCreatedAt={generateCreatedAt}
        userSuspended={userSuspended}
      />}
      {showAdminModal && <Modal
        currUser={currUser}
        statusHistory={statusHistory}
        handleCloseModal={handleCloseAdminModal}
        socket={socket}
        title='Admin panel'
        type='admin'
        users={users}
        banners={banners}
        questBox={questBox}
      />}
      <div className={showModal || showAdminModal || showNotificationsModal ? 'overlay-app' : ''}></div>
    </div>
  )
}

export default Header
