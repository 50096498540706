import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const Box = (props) => {
    const template = props.news.author.replace('.', '').replace(' ', '').toLowerCase()
    const id = props.news.id
    const author = props.news.author
    const autorDesc = props.news.authorDesc ? props.news.authorDesc : 'Unesite nešto o sebi/potpis'
    const logo = props.news.logo
    const title = props.news.title ? props.news.title : 'Unesite naslov'
    const sub = props.news.sub ? props.news.sub : 'Unesite podnaslov'
    const source = props.news.source
    const image = props.news.image ? props.news.image : 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg'
    const commentsNum = props.news.comments
    const socket = props.socket
    let date = props.news.createdAt
    date = new Date(date)
    const handleSetIsEditing = props.handleSetIsEditing

    //const [commentsNum, setCommentsNum] = React.useState(0)

    /* const getCommentsCount = async () => {
        try {
            //obriši komentare, ako ih nema ništa 
            const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/pm/count/${author}${id}`)

            if (response.data.status === 1) {
                console.log('count', response.data.count)
                setCommentsNum(response.data.count)
             }
        } catch (e) {

        }
    } 

    React.useEffect(() => {
        getCommentsCount()
    }, [])
*/

    const generateMonthName = (monthNum) => {
        switch (monthNum) {
            case 1:
                return 'Sij'
                break
            case 2:
                return 'Vel'
                break
            case 3:
                return 'Ožu'
                break
            case 4:
                return 'Tra'
                break
            case 5:
                return 'Svi'
                break
            case 6:
                return 'Lip'
                break
            case 7:
                return 'Srp'
                break
            case 8:
                return 'Kol'
                break
            case 9:
                return 'Ruj'
                break
            case 10:
                return 'Lis'
                break
            case 11:
                return 'Stu'
                break
            case 12:
                return 'Pro'
                break
        }
    }

    const generateDay = () => {
        const day = date.getDate()
        return `${day < 10 ? `0${day}` : day}`
    }

    const generateMonth = () => {
        const month = date.getMonth() + 1
        return generateMonthName(month)
    }

    const generateCommentsLabel = () => {
        if (props.news.comments === 11) {
            return 'komentara'
        } else if (props.news.comments % 10 !== 1) {
            return 'komentara'
        } else {
            return 'komentar'
        }
    }

    const commentsLabel = generateCommentsLabel()
    let meta = props.news.meta
    meta = Array.from(meta)
    meta = [...meta]

    const renderMeta = () => {
        /* if (meta[0].text === '') {
            return `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
        } */

        return meta.map((m,) => {
            return m.link !== ''
                ?
                <a key={`${Math.random()}${m.text}`} className="box-meta-item" href={m.link} target="_blank">{m.text}</a>
                :
                <a key={`${Math.random()}${m.text}`} className="box-meta-item">{m.text}</a>
        })
    }

    const goToSource = () => {
        window.open(source)
    }

    const handleOpenNews = () => {
        if (props.isInModal) {
            props.setType('singlenews')
            props.setCurrSingleNews(id)
            return
        }

        props.handleOpenSingleNewsModal(id)
    }

    const handleEditNews = () => {
        props.setType('addnews')
        props.setCurrSingleNews(id)
        handleSetIsEditing()
    }

    const handleDeleteNews = async (idToDelete) => {
        try {
            //obriši tu vijest
            const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/delete-news/${idToDelete}`)

            if (response.data.status === 1) {

                try {
                    //obriši komentare, ako ih nema ništa 
                    const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ?
                        REACT_APP_DATABASE_BASEURL_DEV :
                        REACT_APP_DATABASE_BASEURL}/pm/${author}${id}`)
                } catch (e) {

                }
                //rifrešaj vijesti
                //props.getNews()
                socket.emit('rifreshnews')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const showDeleteIco = () => {
        if (props.currUser === author) {
            return true
        }

        if (props.isInModal && props.currUser === 'TheCreator') {
            return true
        }

        if (props.isInModal && props.currUser === 'Superadmin') {
            return true
        }

        return false
    }

    return (
        <div className={`box box${props.isInSidebar && '-column'} relative`}>
            {showDeleteIco() && <span onClick={() => { handleDeleteNews(id) }} className="notification-number-alt logout-ico pointer">x</span>}
            {showDeleteIco() && <span onClick={() => { handleEditNews(id) }} className="notification-number-alt logout-ico pointer margin-top"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAhUlEQVR4nH3QsQqBYRQG4J+BRdyDjVLKqv5JbkBGi9XKFbgHm9XtmAx2i8mq1CM56u/v+7zTqfP01jlFUQta2GH1mev7Kpzi4ZsrOinUxh5DbHFDPwXX0fTEEYMUauIS8Jd5CvZwwivQGY0ULLHBCAcsctfOoumOyb+3jOMtyyyq4G5u+QZ5kJ0E6q+6pwAAAABJRU5ErkJggg==" /></span>}
            <div className={`${props.isInSidebar ? 'newsdate' : 'newsdate-alt'}`}>{generateDay()}
                <span>
                    {generateMonth()}
                </span>
            </div>
            <div className={`box-content box-content${props.isInSidebar && '-column'} ${template}`}>
                <div className="box-title">{title}</div>
                <div onClick={goToSource} className="box-sub underline">{sub}</div>
                <div className="box-meta">
                    {renderMeta()}
                </div>
                <div className="row space-between">
                    <div className={`box-author box-author${props.isInSidebar && '-column'}`}>
                        <div className="box-uthor-img__wrapper">
                            <img className="box-author-img" src={logo} />
                        </div>
                        <div className="box-author-detail">
                            <div className="box-author-name">{author}</div>
                            <div className="box-author-info">{autorDesc}</div>
                        </div>
                    </div>
                    <div className="absolute-bottom-right column right">
                        {props.showBtn && <button onClick={handleOpenNews} className="content-button">{commentsNum === 0 ? 'Komentiraj' : `${commentsNum} ${commentsLabel}`}</button>}
                    </div>
                </div>
            </div>
            <div onClick={goToSource} className={`box-poster box-poster${props.isInSidebar && '-column'}`} style={{ backgroundImage: `url(${image})` }}></div>
        </div>
    )
}

export default Box