import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const PlayListModal = ({
    modalTitle,
    socket,
    generateHeaderCloseIco,
    songs,
    currUser,
    getSongs
}) => {


    const handleDeleteSong = async (id) => {
          const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/song-by-id/${id}`)
        if (response.data.status === 1) {
            socket.emit('deleteSong')
        }
    }

    const showDeleteIco = (author) => {
        console.log('author', author)
        if (currUser === author) {
            return true
        }

        if (currUser === 'TheCreator') {
            return true
        }

        if (currUser === 'Superadmin') {
            return true
        }

        return false
    }

    React.useEffect(() => {
        const handleUpdateSongs = () => {
            getSongs()
        }
        socket.on('updateSongs', handleUpdateSongs)

        return () => {
            socket.off('updateSongs', handleUpdateSongs)
        }
    }, [socket])

    const renderSongs = () => {

        return songs.toReversed().map((song, index) => {
           return <div key={`${Math.random()}${song}`} className="relative w95">
                        {showDeleteIco(song.author) && <span
                            onClick={() => { handleDeleteSong(song.id) }}
                            className="notification-number logout-ico pointer">
                                x
                            </span>}
                        <div className="row">
                            <p className="margin-right">{`${index + 1}.`}</p>
                            <p>{`${song.name}`}</p>
                        </div>
                    </div>

            
        })
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title margin-bottom">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderSongs()}
                </div>
            </div>
        </div>
    )

}

export default PlayListModal