import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const PMQueueList = ({
    modalTitle,
    generateHeaderCloseIco,
    joinRoom,
    currUser,
    generateCreatedAt,
    socket
}) => {

    const [PMQueue, setPMQueue] = React.useState([])

    const generateOrNotCreatedAt = (currMsg) => {
        /* if (pm || isNews) {
          return (
            <div className="row middle">
              <p className="msg-author">{currMsg.name}</p>
              <p className="messagetime">{generateCreatedAt(currMsg.createdAt)}</p>
            </div>
          )
        } else {
          return <p className="msg-author">{currMsg.name}</p>
        } */

        return (
            <p className="messagetime">{generateCreatedAt(currMsg.createdAt)}</p>
        )
    }

    const getPMQueue = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/user-name/${currUser}`,
                {
                    attributes: 'pmQueue'
                }
            )

            if (response.data.status === 1) {
                setPMQueue(response.data.user.pmQueue)
            }
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        getPMQueue()
    }, [currUser])

    React.useEffect(() => {

        /* const handleRefreshUserPMQueue = (user) => {
            if (currUser === user) {
                getPMQueue()
            }
        } */

            const handleRefreshUserPMQueue = () => {
                getPMQueue()
            }

        socket.on('refreshUserPMQueue', handleRefreshUserPMQueue)

        return () => {
            socket.off('refreshUserPMQueue', handleRefreshUserPMQueue)
        }

    }, [socket])

    const renderPMQueue = () => {
        return PMQueue.sort((a, b) => {
            if (a.red === false && b.red === true) {
                return -1; // 'a' comes before 'b'
            } else if (a.red === true && b.red === false) {
                return 1; // 'b' comes before 'a'
            } else {
                return 0; // Leave them unchanged
            }
        }).map((pm) => {
            return (
                <div className="message__chats margin-top" key={pm.room}>
                    <div className="pmqueue-single-wrap">
                        <img className="avatar-img" src={pm.fromAvatar}></img>
                        <p><span className="pmqueue-single-wrap__span">Razgovor sa</span> {pm.from}</p>
                    </div>
                    <div className="row middle">
                        <div onClick={() => { joinRoom(pm) }} className={`${pm.red === true ? 'message__recipient' : 'message__sender'} pointer margin-bottom`}>
                            <p>{pm.text}</p>
                        </div>
                        {pm.createdAt && <p>{generateOrNotCreatedAt(pm)}</p>}
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderPMQueue()}
                </div>

            </div>
        </div>
    )
}

export default PMQueueList