import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const shoutBotLength = 55
const shoutBotMinLength = 10
const shoutDescMinLength = 100
const shoutDescMaxLength = 500

const Shout = ({
    generateHeaderCloseIco,
    currUser,
    socket,
    towns,
    setType,
    myShout
}) => {

    const topLocation = React.useRef(null)
    const lastLocation = React.useRef(null)
    const [titleBorder, setTitleBorder] = React.useState('')
    const [descBorder, setDescBorder] = React.useState('')
    const [addType, setAddType] = React.useState('')
    const [addCat, setAddCat] = React.useState('')
    const [addDesc, setAddDesc] = React.useState('')
    const [currShoutId, setCurrShoutId] = React.useState(null)
    const [currShoutAuthor, setCurrShoutAuthor] = React.useState(currUser)
    const [tipLabel, setTipLabel] = React.useState('Vrsta shoutbota')
    const [catLabel, setCatLabel] = React.useState('Kategorija shoutbota')
    const [locLabel, setLocLabel] = React.useState('Lokacija shoutbota')
    const [titleLabel, setTitleLabel] = React.useState('')
    const [descLabel, setDescLabel] = React.useState('')
    const [tipBorder, setTipBorder] = React.useState('')
    const [catBorder, setCatBorder] = React.useState('')
    const [locBorder, setLocBorder] = React.useState('')
    const [town, setTown] = React.useState('')
    const [what, setWhat] = React.useState(myShout ? '' : 'Naslov oglasa')
    const [checkBox, setCheckBox] = React.useState(false)
    const [infoColor, setInfoColor] = React.useState('')
    const [shoutInfo, setShoutInfo] = React.useState('Popunite formular i pritisnite shout botun. Oglasi se mogu predati POTPUNO ANONIMNO. Ne treba ostavljat kontakt podatke. Ako je netko zainteresiran dobit ćeš notifikacju i TI biraš hoćeš li se javit toj osobi ili ne. Shoutovi se automatski brišu nakon 3 mjeseca a možeš ih imat maksimalno 3.')
    const [image, setImage] = React.useState('')
    const [imageBorder, setImageBorder] = React.useState('')
    const [imageLabel, setImageLabel] = React.useState('')

    React.useEffect(() => {
        if (myShout) {
            resetAllFields()
            setCurrShoutId(myShout.id)
            setAddType(myShout.addType)
            setAddCat(myShout.addCat)
            setTown(myShout.town)
            setWhat(myShout.what)
            setAddDesc(myShout.addDesc)
            setCurrShoutAuthor(myShout.author)
            setImage(myShout.image)
            setTipBorder('orange')
            setCatBorder('orange')
            setLocBorder('orange')
            setTitleBorder('orange')
            setDescBorder('orange')
            setImageBorder('orange')
            setTipLabel('Vrsta shoutbota')
            setCatLabel('Kategorija')
            setLocLabel('Lokacija')
            setImageLabel('')
        }
    }, [])

    const resetAllFields = () => {
        setAddType('')
        setAddCat('')
        setTown('')
        setWhat('')
        setImage('')
        setAddDesc('')
        setTipBorder('white')
        setCatBorder('white')
        setLocBorder('white')
        setImageBorder('')
        setTitleBorder('')
        setTitleLabel('')
        setDescBorder('')
        setDescLabel('')
        setCheckBox(false)
        setTipLabel('Vrsta shoutbota')
        setCatLabel('Kategorija shoutbota')
        setLocLabel('Lokacija shoutbota')
        setImageLabel('Slika shoutbota')
        setCurrShoutId(null)
    }

    const handleAddTypeChange = async (e) => {
        setAddType(e.target.value)
        setTipBorder(e.target.value === 'All' ? 'orange' : 'MediumAquaMarine')
        setTipLabel('Tip shoutbota')
    }

    const checkImage = (inputValue) => {
        const imagePattern = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;

        if (imagePattern.test(inputValue)) {
            return true
        }
        return false
    }

    const handleImageChange = (e) => {
        setImage(e.target.value)
        if (e.target.value.length === 0) {
            setImageBorder('white')
            setImageLabel('')
        } else if (!checkImage(e.target.value)) {
            setImageLabel(`Link na sliku nije dobar`)
            setImageBorder('orange')
        } else if (checkImage(e.target.value)) {
            setImageLabel('')
            setImageBorder('MediumAquaMarine')
        }
    }

    const handleSetCheckbox = () => {
        setCheckBox(!checkBox)
    }

    const handleOpenModal = () => {
        setType('uvjetiKoristenja')
    }

    const handleWhatChange = (e) => {
        setWhat(e.target.value)
        if (e.target.value.length === 0) {
            setTitleBorder('white')
            setTitleLabel('')
        } else if (e.target.value.length < shoutBotMinLength) {
            setTitleLabel(`Naslov mora bit veći od ${shoutBotMinLength} znakova`)
            setTitleBorder('orange')
        } else if (e.target.value.length >= shoutBotMinLength && e.target.value.length <= shoutBotLength) {
            setTitleLabel('')
            setTitleBorder('MediumAquaMarine')
        }
    }

    const handleAddCatChange = async (e) => {
        setAddCat(e.target.value)
        setCatBorder(e.target.value === 'All' ? 'orange' : 'MediumAquaMarine')
        setCatLabel('Kategorija')
    }

    const handleAddDescChange = (e) => {
        lastLocation.current.scrollIntoView();
        setAddDesc(e.target.value)
        if (e.target.value.length === 0) {
            setDescBorder('white')
            setDescLabel('')
        } else if (e.target.value.length < shoutDescMinLength) {
            setDescLabel(`Opis mora bit veći od ${shoutDescMinLength} znakova`)
            setDescBorder('orange')
        } else if (e.target.value.length >= shoutDescMinLength && e.target.value.length <= shoutDescMaxLength) {
            setDescLabel('')
            setDescBorder('MediumAquaMarine')
        }
    }

    const checkFields = () => {

        if (
            what.length < shoutBotMinLength ||
            town.length === 0 ||
            addType.length === 0 ||
            addCat.length === 0 ||
            addDesc.length < shoutDescMinLength ||
            image.length === 0
        ) {
            return true
        }

        if (
            towns.length !== 0 &&
            addType.length !== 0 &&
            addCat.length !== 0 &&
            titleLabel.length === 0 &&
            descLabel.length === 0 &&
            imageLabel.length === 0 &&
            checkBox
        ) {
            return false
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newMM = {
            author: currShoutAuthor,
            what,
            addType,
            addCat,
            town,
            addDesc,
            image,
            reacted: []
        }

        if (currShoutId) {
            //znači da se editira
            try {
                const response = await axios.put(`${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/shoutbox/${currShoutId}`,
                    newMM,
                    {
                        headers: {
                            'authorization': localStorage.getItem('token'),
                            'Content-Type': 'application/json'
                        }
                    })

                if (response.data.status === 1) {
                    setShoutInfo(response.data.message)
                    setInfoColor('MediumAquaMarine')
                    resetAllFields()
                    topLocation.current.scrollIntoView();
                }
            } catch (e) {
                setShoutInfo(`Nešto je pošlo po zlu (${e.message}).`)
                setInfoColor('orange')
                topLocation.current.scrollIntoView();
            }
        } else {
            //znači stavljamo novog
            try {
                const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/shoutbox`,
                    newMM,
                    {
                        headers: {
                            'authorization': localStorage.getItem('token'),
                            'Content-Type': 'application/json'
                        }
                    })

                if (response.data.status === 1) {
                    setShoutInfo(response.data.message)
                    setInfoColor('MediumAquaMarine')
                    resetAllFields()
                    socket.emit('newMM', newMM)
                    topLocation.current.scrollIntoView();
                } else if (response.data.status === 2) {
                    setShoutInfo(response.data.message)
                    topLocation.current.scrollIntoView();
                    setInfoColor('orange')
                }

            } catch (e) {
                setShoutInfo(`Nešto je pošlo po zlu (${e.message}).`)
                setInfoColor('orange')
                topLocation.current.scrollIntoView();
            }
        }
    }

    const handleTownsChange = async (e) => {
        setTown(e.target.value)
        setLocBorder(e.target.value === 'All' ? 'orange' : 'MediumAquaMarine')
        setLocLabel('Lokacija')
    }

    const handleSetMMQueue = () => {
        resetAllFields()
        setInfoColor('')
        setShoutInfo('Popunite formular i pritisnite shout botun. Shoutovi se automatski brišu nakon 3 mjeseca.')
        setType('matchmakingQueue')
    }

    const renderMM = () => {
        return (
            <div className="margin-right margin-top margin-bottom" ref={topLocation}>
                <form onSubmit={handleSubmit}>
                    <p className="form-p" style={{ color: `${infoColor}` }}>{shoutInfo}</p>
                    <div className="form-wrap">
                        <div className="form-item">
                            <select
                                style={{ borderColor: tipBorder }}
                                value={addType}
                                onChange={handleAddTypeChange}
                                className="mm-input"
                                id="tip"
                                name="tip"
                            >
                                <option disabled selected key="tip" value="">Tip shoutbota:</option>
                                <option key="Prodajem" value="Prodajem">Prodajem</option>
                                <option key="Iznajmljujem" value="Iznajmljujem">Iznajmljujem</option>
                                <option key="Poklanjam" value="Poklanjam">Poklanjam</option>
                                <option key="Nudim" value="Nudim">Nudim</option>
                                <option key="Tražim" value="Tražim">Tražim</option>
                            </select>
                            {tipLabel.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="tip">{tipLabel}</label>}
                        </div>

                        <div className="form-item">
                            <select
                                style={{ borderColor: catBorder }}
                                value={addCat}
                                onChange={handleAddCatChange}
                                className="mm-input"
                                id="cat"
                                name="cat"
                            >
                                <option disabled selected key="kategorija" value="">Kategorija:</option>
                                <option key="Nekretnine" value="Nekretnine">Nekretnine</option>
                                <option key="Auto/moto" value="Auto/moto">Auto/moto</option>
                                <option key="Nautika" value="Nautika">Nautika</option>
                                <option key="Usluge" value="Usluge">Usluge</option>
                                <option key="Hrana/piće" value="Hrana/piće">Hrana/piće</option>
                                <option key="Turizam" value="Turizam">Turizam</option>
                                <option key="Sve za dom" value="Sve za dom">Sve za dom</option>
                                <option key="Kućni ljubimci" value="Kućni ljubimci">Kućni ljubimci</option>
                                <option key="Informatika" value="Informatika">Informatika</option>
                                <option key="Mobiteli" value="Mobiteli">Mobiteli</option>
                                <option key="Audio/video/foto" value="Audio/video/foto">Audio/video/foto</option>
                                <option key="Glazbala" value="Glazbala">Glazbala</option>
                                <option key="Literatura" value="Literatura">Literatura</option>
                                <option key="Sportska oprema" value="Sportska oprema">Sportska oprema</option>
                                <option key="Djeca" value="Djeca">Djeca</option>
                                <option key="Strojevi i alati" value="Strojevi i alati">Strojevi i alati</option>
                                <option key="Odjeća/obuća" value="Odjeća/obuća">Odjeća/obuća</option>
                                <option key="Ostalo" value="Ostalo">Ostalo</option>
                            </select>
                            {catLabel.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="cat">{catLabel}</label>}
                        </div>
                        <div className="form-item">
                            <select
                                style={{ borderColor: locBorder }}
                                onChange={handleTownsChange}
                                value={town}
                                className="mm-input"
                                id="towns"
                                name="towns"
                            >
                                <option disabled selected key="Ngfdfdgf" value="">U mjestu:</option>
                                {towns.map(town => <option key={town} value={town}>{town}</option>)}
                            </select>
                            {locLabel.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="towns">{locLabel}</label>}
                        </div>
                    </div>

                    <div className="form-wrap">
                        <div className="form-item">
                            <input
                                style={{ borderColor: titleBorder }}
                                value={what}
                                maxLength={shoutBotLength}
                                minLength={shoutBotMinLength}
                                onChange={handleWhatChange}
                                className="mm-input w100"
                                id="what"
                                name="what"
                                placeholder={`Unesite naslov`}
                            />
                            {titleLabel.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="what">{titleLabel}</label>}
                        </div>
                    </div>

                    <div className="form-wrap">
                        <div className="form-item">
                            <textarea
                                style={{ borderColor: descBorder }}
                                rows="5"
                                minLength={shoutDescMinLength}
                                maxLength={shoutDescMaxLength}
                                value={addDesc}
                                onChange={handleAddDescChange}
                                className="mm-input w100"
                                id="desc"
                                name="desc"
                                placeholder={`Unesite opis.`}
                            />
                            {descLabel.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="desc">{descLabel}</label>}
                        </div>
                    </div>

                    <div className="form-wrap">
                        <div className="form-item margin-bottom">
                        <label className="fw200 faded f12 margin-top-s" htmlFor="Slika">Zaljepi ispod link na sliku</label>
                            <input
                                type="url"
                                style={{ borderColor: imageBorder }}
                                value={image}
                                onChange={handleImageChange}
                                className="mm-input w100"
                                id="Slika"
                                name="Slika"
                                placeholder='Oglas mora imat sliku...'
                            />
                            {image.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Slika">{imageLabel}</label>}
                            <a className="white form-p margin-top-5" target="_blank" href="https://postimg.cc/">Uplodiraj sliku (nakon uploada na PostImg servisu odaberite direktni link pa ga zaljepi gore)</a>
                        </div>
                    </div>

                    {generateCurrShout()}

                    <div ref={lastLocation} className="margin-top checkbox-wrap">
                        <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={checkBox}
                            onChange={handleSetCheckbox}
                        />
                        <label htmlFor="checkbox" className="fw200 margin-left form-p noselect">Pročitao sam <span onClick={handleOpenModal}>uvodnik autora</span></label>
                    </div>

                    <button disabled={checkFields()} className="margin-top content-button form-btn" type="submit">Shout!</button>
                </form>
            </div>
        )
    }

    const generateMatchmakingHeader = () => {
        return (
            <div onClick={handleSetMMQueue} className="row">
                <img className="margin-right pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJklEQVR4nK2TTU4CQRBGJ+wEZwweQj2MrlTCnh/j3hABr6NuSAhHQAmSaMQbPVPhw3SGbrod7aSSnpqql6+6qrIscoBD4EzWiMV7D3AEPAAr4AV4lL3KN7aYVFgbWAMdUxdQ3FVMKwa7ByYppQk8BQb7lBmsllTKJqcm6LXvzT6rPDqQq/zCdVoDOp7gY+A2AdoDRq5jVW4A0ASWwHmiyjf3Yx6AXcRg26ORatjlBHhyfhTAux67v8dsrA6cvGdj+YCm+OMvwNy24d9KdpqSZ9WbUvw0RQ7bzW5gbHoJwD4wLA/2uqwy5bBR97WTa4uuRvx29WbAZShgIGieqGwG3MUCr1S+rdMOWKAblelXFkgaWeeAhc2pzO7mG1Z57y28DpzK6rGEb2WgARcffAAzAAAAAElFTkSuQmCC" />
                <p className="form-p underline pointer"><span>Povratak na shoutbox</span></p>
            </div>
        )
    }

    const generateCurrShout = () => {
        return (
            <div className="margin-bottom margin-top w100 p-right-20">
                <div className="shout-header">
                    <div className="shout-image-wrap">
                        {/* <img className="shout-image" src={image} /> */}
                        <img className="shout-image" src={`${image.length === 0 ? '/misc/placeholder-image.webp' : image}`} />
                    </div>
                    <div className='shout-content relative'>
                        <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Tip oglasa:</span>{addType}</p>
                        <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Kategorija:</span>{addCat}</p>
                        <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Lokacija:</span>{town}</p>
                        <p className="text-left blue margin-top-s">{what}</p>
                    </div>
                </div>
                <div className='shout-content shout-content-alt'>
                    <p className="text-left">{addDesc}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {generateMatchmakingHeader()}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderMM()}
                </div>

            </div>
        </div>
    )
}

export default Shout