import React from 'react'
import ReactPlayer from 'react-player/lazy'
import axios from 'axios'
import Modal from './Modal'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV,
    REACT_APP_MAX_SONGS_LIMIT
} = process.env;

const youtubeRegEx = new RegExp(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?$/);

const Player = ({ userSuspended, socket }) => {
    const [songs, setSongs] = React.useState([])
    const [play, setPlay] = React.useState(false)
    const [songIndex, setSongIndex] = React.useState(0)
    const [volumeValue, setVolumeValue] = React.useState(0.4)
    const [animationString, setAnimationString] = React.useState('marquee 15s linear infinite')
    const currUser = localStorage.getItem('userName')
    const [userVideo, setUserVideo] = React.useState('')
    const [userTitle, setUserTitle] = React.useState('')
    const [activeScreen, setActiveScreen] = React.useState('radio')
    const [linkPlaceholder, setLinkPlaceholder] = React.useState(`YouTube video link`)
    const [titlePlaceholder, setTitlePlaceholder] = React.useState('Bravo. Sad unesi naslov')
    const [showModal, setShowModal] = React.useState(false)
    const [maxSongsReached, setMaxSongsReached] = React.useState(false)

    const getSongs = async () => {
        const songsList = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/songs`)
        if (songsList.data.status === 1) {
            setSongs(songsList.data.songs)
        }
        
    }

    const getUserSongsCount = async () => {
        const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/song/${currUser}`)
        if (response.data.status === 1) {
            setMaxSongsReached(response.data.userSongsCount >= REACT_APP_MAX_SONGS_LIMIT ? true : false)
        }
    }

    const insertSong = async () => {
        const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/song/${currUser}`, {
            name: `${userTitle} - by ${currUser}`,
            link: userVideo,
            author: currUser
        })
        if (response.data.status === 1) {
            socket.emit('insertSong')
            setUserVideo('')
            setUserTitle('')
            setActiveScreen('radio')
            setMaxSongsReached(response.data.userSongsCount >= REACT_APP_MAX_SONGS_LIMIT ? true : false)
            socket.emit('notify-all-news', {
                msgToSnd: `${currUser} je dodao/la novi YT link u player: ${userTitle}`
            })
        }
    }

    React.useEffect(() => {
        getSongs()
        getUserSongsCount()
    }, [])

    React.useEffect(() => {

        const handleUpdateSongs = () => {
            getSongs()
        }
        socket.on('updateSongs', handleUpdateSongs)

        return () => {
            socket.off('updateSongs', handleUpdateSongs)
        }
    }, [socket])

    const handleShowModal = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleSetRadioScreen = () => {
        setActiveScreen('radio')
    }

    const handleSetLinkScreen = () => {
        setActiveScreen('link')
    }

    const handleSetTitleScreen = async () => {
        if (youtubeRegEx.test(userVideo) === true) {
            setActiveScreen('title')
        } else {
            setUserVideo('')
            setLinkPlaceholder('YouTube link nije dobar')
        }
    }

    const handleInsertVideo = async () => {
        if (userTitle.length < 3) {
            setUserTitle('')
            setTitlePlaceholder('Unesi barem 3 slova brate')
        } else {
            insertSong()
        }
    }

    const handleInputChange = (e) => {
        setUserVideo(e.target.value)
    }

    const handleSetUserTitle = (e) => {
        setUserTitle(e.target.value)
    }

    const handleVolumeChange = (e) => {
        setVolumeValue(Number(e.target.value))
        if (play && e.target.value > 0.99) {
            socket.emit('volumeOnMax', {
                user: currUser,
                song: songs[songIndex]
            })
            return
        }

        if (play && volumeValue < e.target.value) {
            socket.emit('volumeChange', {
                user: currUser,
                song: songs[songIndex]
            })
        }
    }

    const handlePlay = () => {
        setPlay(!play)
    }

    const nextSong = () => {
        if (songs.length - 1 > songIndex) {
            setSongIndex(songIndex + 1)
            setAnimationString('none')
        } else {
            setSongIndex(0)
            setAnimationString('none')
        }
    }

    const prevSong = () => {
        if (songIndex !== 0) {
            setSongIndex(songIndex - 1)
            setAnimationString('none')
        } else {
            setSongIndex(songs.length - 1)
            setAnimationString('none')
        }
    }

    return (
        <div className="my-react-player">
            {activeScreen === 'link' && <div className="w100">

                {maxSongsReached ? 
                <div className="row middle space-between">
                    <p className="f12">Ubacio si maksimalan broj videa. Obriši jedan.</p> 
                    <button className="add-video-btn add-video-btn-alt" onClick={handleSetRadioScreen}>x</button>
                </div>
                : 
                <div className="add-video-wrap">
                    <input
                        autoFocus
                        value={userVideo}
                        onChange={handleInputChange}
                        id="user-video"
                        name="user-video"
                        placeholder={linkPlaceholder}
                    />
                    <div className="add-video-buttons-wrap">
                        <button onClick={handleSetTitleScreen} className="add-video-btn">Dalje</button>
                        <button className="add-video-btn add-video-btn-alt" onClick={handleSetRadioScreen}>x</button>
                    </div>
                </div>
                }                

            </div>}
            {activeScreen === 'title' && <div className="add-video-wrap">
                <input
                    autoFocus
                    value={userTitle}
                    onChange={handleSetUserTitle}
                    id="user-title"
                    name="user-title"
                    placeholder={titlePlaceholder}
                />
                <div className="add-video-buttons-wrap">
                    <button onClick={handleInsertVideo} className="add-video-btn">Unesi</button>
                    <button className="add-video-btn add-video-btn-alt" onClick={handleSetRadioScreen}>x</button>
                </div>
            </div>}
            {activeScreen === 'radio' && <div className="radio-wrap">
                <div className="player-text-div">
                    <p onClick={handleShowModal} className="margin-right pointer f18 radio-text-color">&equiv;</p>
                    {!userSuspended && <p onClick={handleSetLinkScreen} className="margin-right pointer f18 radio-text-color">&#43;</p>}
                    {play && <p className="song-number">{`${songIndex + 1}.`}</p>}
                    {play ? <p className="text-carousel song-text">
                        <span
                            style={{ animation: `${animationString}` }}>
                            {songs[songIndex].name}
                        </span>
                    </p> :
                        <p
                            className="text-carousel">
                            nethr.chat playlista
                        </p>}
                </div>
                <div className="player-wrap-div">
                    <div className="player-controls-wrap">
                        <div onClick={handlePlay} className="player-control-wrap">
                            <a>{play ?
                                <p className="player-control-wrap-pause-sign">=</p> :
                                <p className="player-control-wrap-play-sign">&#9658;</p>}</a>
                        </div>
                        <div onClick={prevSong} className="player-control-wrap">
                            <p>{'<'}</p>
                        </div>
                        <div onClick={nextSong} className="player-control-wrap">
                            <p>{'>'}</p>
                        </div>
                        {songs.length !== 0 && <a href={`${songs[songIndex] === undefined ? '#' : songs[songIndex].link}`} target="_blank" className="player-control-wrap player-control-wrap-yt">
                            <p>YT</p>
                        </a>}

                    </div>
                    <div className="slider-wrap">
                        <input onChange={handleVolumeChange} type="range" min="0" max="1.0" step="0.1" value={volumeValue} className="slider" id="myRange"></input>
                    </div>
                </div>

                {songs.length !== 0 && <ReactPlayer
                    className='player'
                    url={songs[songIndex].link}
                    playing={play}
                    controls={true}
                    volume={volumeValue}
                    width='100%'
                    height='100%'
                    muted={false}
                    onEnded={nextSong}
                    onPlay={() => { setAnimationString('marquee 15s linear infinite') }}
                />}
            </div>}
            {showModal && <Modal
                currUser={currUser}
                handleCloseModal={handleCloseModal}
                socket={socket}
                type="playlist"
                songs={songs}
                getSongs={getSongs}
            />}
            <div className={showModal ? 'overlay-app' : ''}></div>
        </div>
    )
}

export default Player