import React from 'react'

const GameBox = ({
    socket,
    users,
    gamers,
    title,
    sub,
    btnText = 'empty',
    currUser = localStorage.getItem('userName'), //moram ga vadit iz localstorage-a zato jer currUser bude undefined svako drugi put, zašto?
    imgSrc = "https://assets.codepen.io/3364143/glass.png",
    handleKickMp,
    sub2, 
    sub3,
    handleOpenEventsModal,
    isInSidebar,
    isInChatBody,
    userSuspended
}) => {

    const [titleText, setTitleText] = React.useState(title)
    const [timerText, setTimerText] = React.useState('')
    const [currIndex, setCurrIndex] = React.useState(-1)

    React.useEffect(() => {

        const handleGameBoxTimer = newTimerText => {
            if (sub === 'Multiplayer završava za:') {
                setTimerText(newTimerText)
            }
        }

        socket.on('gameBoxTimer', handleGameBoxTimer)

        return () => {
            socket.off('gameBoxTimer', handleGameBoxTimer)
        }
    }, [socket, timerText])

    React.useEffect(() => {

        const handleUpdateGameBoxSub = data => {
            if (data.name === currUser) {
                // setTitleText(data.newSub) - ovo je da nestaje tekst koji se utipkao već
                setCurrIndex(data.currIndex)

                //ovo ako je zadnji znak koji treba napisat gamer onda okida typing event, jer je tu listener za završit multiplayer i pobjedit
                if (data.currIndex === titleText.length - 1) {
                    socket.emit("typing", { key: 'Enter', name: currUser })
                }
            }
        }

        socket.on('updateGameBoxSub', handleUpdateGameBoxSub)

        return () => {
            socket.off('updateGameBoxSub', handleUpdateGameBoxSub)
        }


    }, [socket, titleText])

    const generateTitleText = () => {
        if (!Object.hasOwn(gamers, currUser)) {
            return
        }
        return titleText.split('').map((t, i) => {
            if (i === currIndex + 1 && t !== ' ') {
                return <span className="next-sign">{t}</span>
            } else if (i === currIndex + 1 && t === ' ') {
                return <span className="empty-space next-sign">&#9248;</span>
            } else if (i <= currIndex) {
                return <span className="eliminated-sign">{t}</span>
            } else {
                return t
            }
        })
    }

    const generateGameBoxGamers = () => {
        if (!gamers) {
            return
        }

        if (sub !== "Multiplayer završava za:" || timerText === 'Multiplayer završen!') {
            return
        }

        if (!Object.hasOwn(gamers, currUser)) {
            return
        }

        let usersToMap = users.filter(u => u.type === 'gamer')

        return (
            <div className="mobile-gamers-wrap">
                {usersToMap.map((user, index) => {
                    return <div key={`${user}${Math.random()}`}
                        style={{
                            left: `${gamers[user.userName] / titleText.length * 85}%`
                        }}
                        className="mobile-gamer">
                        <img className="avatar-img mob-gamer-correction" src={user.avatar}></img>
                    </div>
                })}
            </div>
        )
    }

    /* 
    //ako treba izbrisat quest text iz chata kad bude mp gotov
    if (timerText === 'Multiplayer završen!') {
        return
    } */

    const handleBtnClick = () => {
        if (btnText === 'Opširnije') {
            handleOpenEventsModal()
            return
        }

        if (Object.hasOwn(gamers, currUser)) {
            handleKickMp(currUser)
        } else {
            socket.emit('game', currUser)
        }
    }

    const generateGamersDrivers = () => {
        if (!gamers) {
            return
        }

        if (Object.hasOwn(gamers, currUser)) {
            return
        }

        if (sub !== "Multiplayer završava za:" || timerText === 'Multiplayer završen!') {
            return
        }

        let usersToMap = users.filter(u => u.type === 'gamer')

        return usersToMap.map((user) => {
            return (
                <div key={`${user}${Math.random()}`} className="mobile-gamers-wrap driver">
                    <div
                        style={{
                            left: `${gamers[user.userName] / titleText.length * 85}%`
                        }}
                        className="mobile-gamer row middle">
                        <img className="avatar-img mob-gamer-correction" src={user.avatar}></img>
                        
                    </div>
                    <p className="driver-username margin-left">{user.userName}</p>
                </div>
            )
        })
    }

    const generateGamers = () => {
        if (!gamers) {
            return
        }

        if (Object.keys(gamers).length === 0) {
            return
        }

        if (title !== 'Multiplayer') {
            return
        }

        let usersToMap = users.filter(u => u.type === 'gamer')

        return (
            <div className="home-gamers-wrap">
                {usersToMap.map((user, index) => {
                    return <div key={`${user}${Math.random()}`}
                        style={{
                            left: `${index * 10}%`
                        }}
                        className="home-gamer">
                        <img className="avatar-img hgamers-img" src={user.avatar}></img>
                    </div>
                })}
            </div>
        )

    }

    const generatePartyImgStyle = () => {
        if (btnText === 'Opširnije' || sub3) {
            return 'partyimg'
        } 

        //go text da ne prelazi igrače na mobilnoj
        if (imgSrc === "go-white.png") {
            return 'goImg'
        }
    }

    return (
        <div className={`content-wrapper-header ${isInChatBody && 'in-chat-body'}`}>
            <div className={`content-wrapper-header-col1 content-wrapper-header-col1${isInSidebar && '-column'}`}>
                {generateGamersDrivers()}
                {sub === "Multiplayer završava za:" && <div className="gamebox-title"><p className="noselect">{generateTitleText()}</p></div>}
                {sub !== "Multiplayer završava za:" && <div className="gamebox-title row"><p>{titleText}</p>{generateGamers()}</div>}
                {timerText === '' && <div className="content-text noselect margin-bottom">{sub}</div>}
                {timerText !== '' && <div className="content-text noselect">{timerText}</div>}
                {generateGameBoxGamers()}
                {sub2 && <div className="content-text noselect margin-negative-top-20">{sub2}</div>}
                {sub3 && <div className="content-text noselect margin-negative-top">{sub3}</div>}
                {btnText !== 'empty' && !userSuspended && <a onClick={handleBtnClick} className="content-button margin-bottom margin-top">{btnText}</a>}
            </div>
            <div className="content-wrapper-context">
                <img className={`${isInChatBody && 'img-in-chat-body'} ${isInSidebar && 'sidebar-img'} content-wrapper-img ${sub === 'Čestitke pobjedniku' && 'rounded'} ${generatePartyImgStyle()}`} src={imgSrc} alt="" />
            </div>

        </div>
    )
}

export default GameBox