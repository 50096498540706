import React from 'react'
import axios from 'axios'
import SeasonBox from './SeasonBox'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const SeasonsModal = ({
    modalTitle,
    generateHeaderCloseIco, 
    socket
}) => {

    const [seasonsList, setSeasonsList] = React.useState(null)

    const getSeasonsList = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/seasons`)

            if (response.data.status === 1) {
                setSeasonsList(response.data.season)
            }

        } catch (e) {

        }
    }

    React.useEffect(() => {
        getSeasonsList()
        console.log('11111111111111', seasonsList)
    }, [])

    React.useEffect(() => {
        const handleGetSeasonsList = () => {
            getSeasonsList()
        }

        socket.on('rifreshSeasons', handleGetSeasonsList)

        return () => {
            socket.off('rifreshSeasons', handleGetSeasonsList)
        }
    }, [socket])

    const renderSeasons = () => {
        return (
            <div className="margin-right-15">
                {seasonsList.map(season => {
                    return <SeasonBox season={season} showBtn={false} />
                })}
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title margin-bottom">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {seasonsList && renderSeasons()}
                </div>
            </div>
        </div>
    )
}

export default SeasonsModal