import React, { useEffect, useState, useRef } from 'react'
import Linkify from 'react-linkify';
import GameBox from './GameBox'
//import Box from './Box'
import axios from 'axios'
//import { isMobile } from 'react-device-detect';

const {
  REACT_APP_DATABASE_BASEURL_DEV,
  REACT_APP_DATABASE_BASEURL,
  REACT_APP_NODE_ENV,
  REACT_APP_SERVER_BASEURL_DEV,
  REACT_APP_SERVER_BASEULR
} = process.env;

const ChatBody = ({ me,
  isNews,
  handleOpenSingleNewsModal,
  socket,
  //userHasScrolled, 
  pm,
  to,
  room = 'Public',
  from,
  currUser,
  users,
  gamers,
  news,
  generateCreatedAt,
  userSuspended
}) => {
  const [messages, setMessages] = useState([])
  const lastMessageRef = useRef(null)
  const [currChatUsers, setCurrChatUsers] = useState([])
  const [fightDisabled, setFightDisabled] = useState(false)
  currUser = localStorage.getItem('userName')
  const [currTyper, setCurrTyper] = useState(null)

  const getCurrChatUsers = async (messageList) => {
    const userNamesToGet = (messageList.map(m => m.name).filter((value, index, self) => self.indexOf(value) === index))
    try {
      const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ?
        REACT_APP_DATABASE_BASEURL_DEV :
        REACT_APP_DATABASE_BASEURL}/users`, {
        "where": {
          'name': userNamesToGet
        }
      })

      if (response.data.status === 1) {
        setCurrChatUsers(response.data.data)
        setFightDisabled(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getPublicMessageHistory = async () => {
    try {
      const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ?
        REACT_APP_DATABASE_BASEURL_DEV :
        REACT_APP_DATABASE_BASEURL}/pm/Public`, {
        headers: {
          'authorization': localStorage.getItem('token')
        }
      })

      if (response.data.status === 1) {
        setMessages(response.data.pm.chatHistory)
        getCurrChatUsers(response.data.pm.chatHistory)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getCurrChatHistory = async () => {
    try {
      const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ?
        REACT_APP_DATABASE_BASEURL_DEV :
        REACT_APP_DATABASE_BASEURL}/pm/${room}`, {
        headers: {
          'authorization': localStorage.getItem('token')
        }
      })

      if (response.data.status === 1) {
        setMessages(response.data.pm.chatHistory)
        getCurrChatUsers(response.data.pm.chatHistory)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (pm || isNews) {
      getCurrChatHistory()
    } else {
      getPublicMessageHistory()
    }
  }, [])


  useEffect(() => {
    const handleRefreshMsgs = () => {
      if (pm || isNews) {
        getCurrChatHistory()
      } else {
        getPublicMessageHistory()
      }
    }

    socket.on('rifreshChatMsgs', handleRefreshMsgs)

    return () => {
      socket.off('rifreshChatMsgs', handleRefreshMsgs)
    }

  }, [socket])

  useEffect(() => {

    const handlePmMessageResponse = data => {
      if (data.room !== room) {
        return
      }

      if (data.name === to || data.name === from) {
        setMessages([...messages, data])
        getCurrChatUsers([...messages, data])
      }
    }

    const handleNewsMessageResponse = (data, msgRoom) => {
      if (msgRoom === room || data.type === 'invisible') {
        setMessages([...messages, data])
        getCurrChatUsers([...messages, data])
      }
    }

    const handleMessageResponse = data => {
      if (pm || isNews) {
        return
      }
      setMessages([...messages, data])
      getCurrChatUsers([...messages, data])
    }

    const handleUpdateChatAfterDeletionResponse = (msgs, currRoom) => {
      if (currRoom === room) {
        setMessages(msgs)
      }
    }

    socket.on("pm-message-response", handlePmMessageResponse);
    socket.on("news-messageResponse", handleNewsMessageResponse);
    socket.on("messageResponse", handleMessageResponse);
    socket.on("updateChatAfterDeletionResponse", handleUpdateChatAfterDeletionResponse);

    return () => {
      socket.off("pm-message-response", handlePmMessageResponse);
      socket.off("news-messageResponse", handleNewsMessageResponse);
      socket.off("messageResponse", handleMessageResponse);
      socket.off("updateChatAfterDeletionResponse", handleUpdateChatAfterDeletionResponse);
    };


    /* if (pm) {
     socket.on("pm-message-response", data => {
       if (data.name === to || data.name === from) {
         setMessages([...messages, data])
         getCurrChatUsers([...messages, data])
       }
     })
   } else if (isNews) {
     socket.on("news-messageResponse", (data, msgRoom) => {
       console.log("news-messageResponse", data)
       if (msgRoom === room || data.type === 'invisible') {
         setMessages([...messages, data])
         getCurrChatUsers([...messages, data])
       }
     })
   } else if (!pm) {
     socket.on("messageResponse", data => {
       setMessages([...messages, data])
       getCurrChatUsers([...messages, data])
     })
   }   */


  }, [socket, messages])

  useEffect(() => {

    /* if (userHasScrolled || pm || isNews) {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    } */
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });

  }, [messages]);

  useEffect(() => {
    const handleTypingResponse = (nameOfTheTyper, roomToTest) => {

      if (roomToTest !== room) {
        return
      }

      setCurrTyper(nameOfTheTyper)
    }

    // Handle typing stopped response from the server
    const handleTypingStoppedResponse = (roomToTest) => {

      if (roomToTest !== room) {
        return
      }

      setCurrTyper(null); // Clear the typing indicator when the server sends typing stopped
    };

    socket.on("typingResponse", handleTypingResponse)
    socket.on("typingResponseStopped", handleTypingStoppedResponse);

    return () => {
      socket.off("typingResponse", handleTypingResponse)
      socket.off("typingResponseStopped", handleTypingStoppedResponse);
    }
  }, [socket])

  const handleFightBtnClick = (type, name, text) => {

    if (fightDisabled) {
      return
    }
    setFightDisabled(true)

    socket.emit('fight', {
      type,
      name,
      user: localStorage.getItem("userName"),
      avatar: me.avatar,
      text
    }
    )
  }

  const handleRemoveMessage = (msgId) => {
    socket.emit('updateChatAfterDeletion', messages.filter(m => m.id !== msgId), msgId, room, (news && news.id) || undefined)
  }

  const isAdmin = () => {
    if (currUser === 'TheCreator' || currUser === 'Superadmin') {
      return true
    } else {
      return false
    }
  }

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
  };

  const youtubeLinkRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  const imageRegex = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;

  const generateOrNotCreatedAt = (currMsg) => {
    /* if (pm || isNews) {
      return (
        <div className="row middle">
          <p className="msg-author">{currMsg.name}</p>
          <p className="messagetime">{generateCreatedAt(currMsg.createdAt)}</p>
        </div>
      )
    } else {
      return <p className="msg-author">{currMsg.name}</p>
    } */

    return (
      <div className="row middle">
        <p className="msg-author">{currMsg.name}</p>
        <p className="messagetime">{generateCreatedAt(currMsg.createdAt)}</p>
      </div>
    )
  }


  const getMessageList = () => {

    return (
      messages.map(message => {
        const match = message.text.match(youtubeLinkRegex);
        const isImage = message.text.match(imageRegex);
        let energy
        const userIndex = currChatUsers.findIndex(u => u.name === message.name)
        energy = userIndex !== -1 ? currChatUsers[userIndex].energy : 100
        const isInShield = userIndex !== -1 && currChatUsers[userIndex].shield.findIndex(s => s === currUser)
        const isInSword = userIndex !== -1 && currChatUsers[userIndex].sword.findIndex(s => s === currUser)

        const fightBtnsHidden = fightDisabled || message.name === currUser || userIndex === -1 || pm || userSuspended

        if (isImage) {
          return (
            <div className="message__chats" key={message.id} >
              {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
              <div className="msg-wrap relative">
                {isAdmin() && <span onClick={() => handleRemoveMessage(message.id)} className="notification-number-alt logout-ico pointer">x</span>}
                <img className="chat-img margin-right" src={message.text} />
                {!fightBtnsHidden ? <div className="chat-icons-wrap">
                  {energy !== 100 && isInShield === -1 && <button onClick={handleFightBtnClick.bind(null, 'shield', message.name, message.text)}>
                    <img src="https://img.icons8.com/ios-filled/50/FFFFFF/favorites-shield.png" />
                  </button>}
                  {energy !== 0 && isInSword === -1 && <button onClick={handleFightBtnClick.bind(null, 'sword', message.name, message.text)}>
                    <img src="https://img.icons8.com/ios-filled/50/FFFFFF/sword.png" />
                  </button>}
                </div> : null}
              </div>
            </div >
          )
        }

        if (match) {
          const videoId = match[5];
          const embedCode = `<iframe class="yt-iframe" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
          return (
            <div className="message__chats" key={message.id} >
              {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
              <div className="msg-wrap relative">
                {isAdmin() && <span onClick={() => handleRemoveMessage(message.id)} className="notification-number-alt logout-ico pointer">x</span>}
                <div className="yt-container-wrap row">
                  <div className="margin-bottom margin-right yt-container" dangerouslySetInnerHTML={{ __html: embedCode }
                  } />
                  {!fightBtnsHidden ? <div className="chat-icons-wrap">
                    {energy !== 100 && <button onClick={handleFightBtnClick.bind(null, 'shield', message.name, message.text)}>
                      <img src="https://img.icons8.com/ios-filled/50/FFFFFF/favorites-shield.png" />
                    </button>}
                    {energy !== 0 && <button onClick={handleFightBtnClick.bind(null, 'sword', message.name, message.text)}>
                      <img src="https://img.icons8.com/ios-filled/50/FFFFFF/sword.png" />
                    </button>}
                  </div> : null}
                </div>
              </div>
            </div >
          )
        }

        if (message.name === 'TheCreator') {
          if (pm) {
            return (
              <div className={currUser === message.name ? "message__chats_right" : "message__chats"} key={message.id}>
                <div className="row">
                  <img className="avatar-img" src={message.avatar} />
                  <div className="margin-left">
                    {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
                    <div className="msg-wrap">
                      <div className='creator winner game-bg'>
                        <Linkify componentDecorator={(href, text, key) => (
                          <a href={href} key={key} onClick={handleLinkClick}>{message.text}</a>
                        )}>
                          <p>{message.text}</p>
                        </Linkify>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          return (
            <div className="message__chats row" key={message.id}>
              <img className="avatar-img" src={message.avatar} />
              <div className="margin-left">
                {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
                <div className="msg-wrap relative">
                  {isAdmin() && <span onClick={() => handleRemoveMessage(message.id)} className="notification-number-alt logout-ico pointer">x</span>}
                  <div className='creator winner game-bg'>
                    <Linkify componentDecorator={(href, text, key) => (
                      <a href={href} key={key} onClick={handleLinkClick}>{message.text}</a>
                    )}>
                      <p>{message.text}</p>
                    </Linkify>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        /* if (message.text === 'news') {
          return isMobile ? <Box showBtn={true} handleOpenSingleNewsModal={handleOpenSingleNewsModal} key={`${Math.random()}${message.id}`} news={message.news} /> : null
        } */

        if (message.text === 'game') {
          return <GameBox key={`${Math.random()}${message.id}`} imgSrc={message.imgSrc} users={users} socket={socket} gamers={gamers} title={message.title} sub={message.sub} btnText={message.btnText} isInChatBody={true} />
        }

        if (message.type === 'system' || message.name === 'Superadmin') {
          return (
            <div className="message__chats" key={message.id}>
              {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
              <div className="msg-wrap">
                <div className='winner game-bg'>
                  <Linkify componentDecorator={(href, text, key) => (
                    <a href={href} key={key} onClick={handleLinkClick}>{message.text}</a>
                  )}>
                    <p>{message.text}</p>
                  </Linkify>
                </div>
              </div>
            </div>
          )
        }

        if (message.type === 'invisible') {
          return
        }

        if (message.winner) {
          return (
            <div className={currUser === message.name ? `message__chats${pm ? '_right' : ''} row` : "message__chats row"} key={message.id}>
              <div className="relative">
                <img className="avatar-img" src={message.avatar} />
                <span className="lvl-bubble">{message.level}</span>
              </div>
              <div className="margin-left">
                {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
                <div className="msg-wrap relative">
                  {isAdmin() && <span onClick={() => handleRemoveMessage(message.id)} className="notification-number logout-ico pointer">x</span>}
                  <div className='winner game-bg'>
                    <Linkify componentDecorator={(href, text, key) => (
                      <a href={href} key={key} onClick={handleLinkClick}>{message.text}</a>
                    )}>
                      <p>{message.text}</p>
                    </Linkify>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        if (pm) {
          return (
            <div className={currUser === message.name ? "message__chats_right" : "message__chats"} key={message.id}>
              <div className="row">
                <div className="relative">
                  <img className="avatar-img" src={message.avatar} />
                  <span className="lvl-bubble">{message.level}</span>
                </div>
                <div className="margin-left">
                  {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
                  <div className="message__sender">
                    <Linkify componentDecorator={(href, text, key) => (
                      <a href={href} key={key} onClick={handleLinkClick}>{message.text}</a>
                    )}>
                      <p>{message.text}</p>
                    </Linkify>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        return (

          <div className="message__chats row" key={message.id}>
            <div className="relative">
              <img className="avatar-img" src={message.avatar} />
              <span className="lvl-bubble">{message.level}</span>
            </div>
            <div className="margin-left">
              {message.createdAt && <p>{generateOrNotCreatedAt(message)}</p>}
              <div className="msg-wrap relative">
                {isAdmin() && <span onClick={() => handleRemoveMessage(message.id)} className="notification-number logout-ico pointer">x</span>}
                <div className='message__sender' style={energy !== 100 ?
                  { background: `linear-gradient(to left, rgba(0, 0, 0, .3) ${100 - energy}%, var(--theme-button-color) 100%)` }
                  :
                  { background: 'var(--theme-button-color)' }}>
                  <Linkify componentDecorator={(href, text, key) => (
                    <a href={href} key={key} onClick={handleLinkClick}>{message.text}</a>
                  )}>
                    <p>{message.text}</p>
                  </Linkify>
                </div>
                {!fightBtnsHidden ? <div className="chat-icons-wrap">
                  {energy !== 100 && isInShield === -1 && <button onClick={handleFightBtnClick.bind(null, 'shield', message.name, message.text)}>
                    <img src="https://img.icons8.com/ios-filled/50/FFFFFF/favorites-shield.png" />
                  </button>}
                  {energy !== 0 && isInSword === -1 && <button onClick={handleFightBtnClick.bind(null, 'sword', message.name, message.text)}>
                    <img src="https://img.icons8.com/ios-filled/50/FFFFFF/sword.png" />
                  </button>}
                </div> : null}
              </div>
            </div>
          </div>
        )

      })
    )
  }

  return (
    <>
      <div className={`relative chat-body ${pm ? 'margin-top' : ''}`} style={{ marginRight: pm ? '10px' : '0px' }}>
        {getMessageList()}
        <div ref={lastMessageRef} />
        {currTyper && <p className={`${!pm && !isNews ? 'typer' : 'typeralt'}`}>{`${currTyper} piše...`}</p>}
      </div>
    </>
  )
}

export default ChatBody