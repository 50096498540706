import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Modal from './Modal'
import axios from 'axios'
import { isMobile } from 'react-device-detect';
import multiavatar from '@multiavatar/multiavatar/esm'

const isEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
const isGoodPassPattern = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
const towns = ['Bakar', 'Beli Manastir', 'Belišće', 'Benkovac', 'Biograd na Moru', 'Bjelovar', 'Buje', 'Buzet', 'Cres', 'Crikvenica', 'Čabar', 'Čakovec', 'Čazma', 'Daruvar', 'Delnice', 'Donja Stubica', 'Donji Miholjac', 'Drniš', 'Dubrovnik', 'Duga Resa', 'Dugo Selo', 'Đakovo', 'Đurđevac', 'Garešnica', 'Glina', 'Gospić', 'Grubišno Polje', 'Hrvatska Kostajnica', 'Hvar', 'Ilok', 'Imotski', 'Ivanec', 'Ivanić-Grad', 'Jastrebarsko', 'Karlovac', 'Kastav', 'Kaštela', 'Klanjec', 'Knin', 'Komiža', 'Koprivnica', 'Korčula', 'Kraljevica', 'Krapina', 'Križevci', 'Krk', 'Kutina', 'Kutjevo', 'Labin', 'Lepoglava', 'Lipik', 'Ludbreg', 'Makarska', 'Mali Lošinj', 'Metković', 'Mursko Središće', 'Našice', 'Nin', 'Nova Gradiška', 'Novalja', 'Novigrad', 'Novi Marof', 'Novi Vinodolski', 'Novska', 'Obrovac', 'Ogulin', 'Omiš', 'Opatija', 'Opuzen', 'Orahovica', 'Oroslavje', 'Osijek', 'Otočac', 'Otok', 'Ozalj', 'Pag', 'Pakrac', 'Pazin', 'Petrinja', 'Pleternica', 'Ploče', 'Popovača', 'Poreč', 'Požega', 'Pregrada', 'Prelog', 'Pula', 'Rab', 'Rijeka', 'Rovinj', 'Samobor', 'Senj', 'Sinj', 'Sisak', 'Skradin', 'Slatina', 'Slavonski Brod', 'Slunj', 'Solin', 'Split', 'Stari Grad', 'Supetar', 'Sveta Nedelja', 'Sveti Ivan Zelina', 'Šibenik', 'Trilj', 'Trogir', 'Umag', 'Valpovo', 'Varaždin', 'Varaždinske Toplice', 'Velika Gorica', 'Vinkovci', 'Virovitica', 'Vis', 'Vodice', 'Vodnjan', 'Vrbovec', 'Vrbovsko', 'Vrgorac', 'Vrlika', 'Vukovar', 'Zabok', 'Zadar', 'Zagreb', 'Zaprešić', 'Zlatar', 'Županja']

const {
	REACT_APP_DATABASE_BASEURL_DEV,
	REACT_APP_DATABASE_BASEURL,
	REACT_APP_NODE_ENV,
	REACT_APP_SERVER_DEV,
	REACT_APP_SERVER
} = process.env;

const Home = ({
	socket
}) => {
	const navigate = useNavigate()

	const [userName, setUserName] = useState('')
	const [userEmail, setUserEmail] = useState('')
	const [pass, setPass] = useState('')
	const [birthDate, setBirthDate] = useState(null)
	const [userGender, setUserGender] = useState(null)
	const [town, setTown] = React.useState('')
	const [borderCol, setBorderCol] = useState('')
	const [borderColPass, setBorderColPass] = useState('')
	const [borderColEmail, setBorderColEmail] = useState('')
	const [borderColDate, setBorderColDate] = useState('')
	const [borderColGender, setBorderColGender] = useState('')
	const [locBorder, setLocBorder] = React.useState('')
	const [labelUser, setLabelUser] = useState('')
	const [labelPass, setLabelPass] = useState('')
	const [labelEmail, setLabelEmail] = useState('')
	const [checkBox, setCheckBox] = useState(false)
	const [showModal, setShowModal] = React.useState(false)
	const [avatar, setAvatar] = React.useState('')
	const [descVisible, setDescVisible] = React.useState(true)
	const [boxType, setBoxType] = useState('login')
	const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false)
	const [loginAttempts, setLoginAttempts] = useState(1000)
	const [regScreen, setRegScreen] = useState(1)
	const [authStatusMsg, setAuthStatusMsg] = useState('Ulogiraj se u svoj acc')
	const { token } = useParams()
	const [bgIndex, setBgIndex] = useState(0)
	const [imageBrowserActive, setImageBrowserActive] = useState(false)
	const [bgs, setBgs] = useState([])
	const [showPassword, setShowPassword] = useState(false);

	const sub = bgs.length === 0 ? 'noImage' : bgs[bgIndex].sub
	const link = bgs.length === 0 ? 'noImage' : bgs[bgIndex].link
	const name = bgs.length === 0 ? 'noImage' : bgs[bgIndex].name

	const fetchImages = async () => {
		const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/photos`)
		setBgs(data.data.photos)
		const randomImageIndex = Math.floor(Math.random() * data.data.photos.length)
		setBgIndex(randomImageIndex)
	}

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const setRegScreenManualy = (regScreenToSet) => {
		setRegScreen(regScreenToSet)
		setAuthStatusMsg('')
	}

	const nextRegScreen = () => {
		setRegScreen(regScreen + 1)
		setAuthStatusMsg('')
	}

	const prevRegScreen = () => {
		setRegScreen(regScreen - 1)
		setAuthStatusMsg('')

	}

	const bruteForcePreventionProcedure = () => {
		setSubmitBtnDisabled(true)

		const btnDisabledTimeout = setTimeout(() => {
			setSubmitBtnDisabled(false)
			setLoginAttempts(loginAttempts + 1000)
		}, loginAttempts * 4)

		return () => {
			clearTimeout(btnDisabledTimeout)
		}
	}

	React.useEffect(() => {

		const handleUpdateBgs = () => {
			fetchImages()
		}
		socket.on('updateBgs', handleUpdateBgs)

		return () => {
			socket.off('updateBgs', handleUpdateBgs)
		}
	}, [socket])

	const resetAllFields = () => {
		setUserEmail('')
		setPass('')
		setUserName('')
		setCheckBox(false)
		setLabelEmail('')
		setLabelPass('')
		setLabelUser('')
		setBorderCol('white')
		setBorderColEmail('white')
		setBorderColPass('white')
		setBirthDate(null)
		setUserGender(null)
		setBorderColDate('')
		setBorderColGender('')
		setTown('')
		setLocBorder('')
	}

	const getRandomAvatar = () => {
		const alphabet = "abcdefghijklmnopqrstuvwxyz";
		let randomString = "";

		for (let i = 0; i < 5; i++) {
			const randomIndex = Math.floor(Math.random() * alphabet.length);
			randomString += alphabet[randomIndex];
		}

		setAvatar(multiavatar(randomString))
	}

	const handleChangeBgIndex = (prev, next) => {
		if (prev) {
			setBgIndex((bgIndex - 1 + bgs.length) % bgs.length);
		} else if (next) {
			setBgIndex((bgIndex + 1) % bgs.length)
		}
	}

	const handleToggleIimageBrowser = () => {
		setImageBrowserActive(!imageBrowserActive)
	}

	const verifyToken = async () => {
		try {
			const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/validate`, {}, {
				headers: {
					'Content-Type': 'application/json',
					'authorization': token
				}
			})

			if (data.data.status === 1) {
				setAuthStatusMsg('Hvala. Uspješno ste verificirali svoj račun. Možete se ulogirati.')
			} else {
				setAuthStatusMsg('Aktivacijski link je istekao. Zatraži novi aktivacijski link.')
				setBoxType('email')
			}
		} catch (e) {
			setAuthStatusMsg(e.message)
		}
	}

	useEffect(() => {
		getRandomAvatar()
		fetchImages()

		if (token) {
			verifyToken()
		}
	}, [])

	const handleSubmit = async () => {

		const generateGender = () => {
			if (userGender === 'M') {
				return 'muško'
			} else if (userGender === 'Ž') {
				return 'žensko'
			} else if (userGender === 'N') {
				return 'nebinaran'
			}
		}

		try {
			const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/register`, {
				name: userName,
				email: userEmail,
				password: pass,
				gender: generateGender(),
				birthDate,
				location: town,
				status: 1,
				pmQueue: [],
				notifyQueue: [],
				galerija: [],
				avatar: `${REACT_APP_NODE_ENV === 'development' ? REACT_APP_SERVER_DEV : REACT_APP_SERVER}/media/avatars/${userName.replace(/[^a-zA-Z0-9 ]/g, '')}.svg`
			}, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			setAuthStatusMsg(data.data.message)

			if (data.data.status === 1) {
				resetAllFields()
				setBoxType('login')

				socket.emit("saveAvatar", {
					avatar,
					userName: userName.replace(/[^a-zA-Z0-9 ]/g, '')
				})
			}

		} catch (e) {
			setAuthStatusMsg(e.message)
		}
	}

	const handleLoginSubmit = async () => {

		try {
			const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/login`, {
				email: userEmail,
				password: pass,
			}, {
				headers: {
					'Content-Type': 'application/json'
				}
			})

			if (data.data.status === 1) {
				resetAllFields()
				setAuthStatusMsg(data.data.message)
				localStorage.setItem('token', data.data.token)
				localStorage.setItem("userName", data.data.userName)
				socket.emit("newUser", {
					userName: data.data.userName,
					id: data.data.id,
					socketID: socket.id,
					isMobile,
					token: data.data.token,
					avatar: data.data.avatar,
					winner: data.data.winner,
					level: data.data.level
				})
				navigate("/chat")
			} else if (data.data.status === 2) {
				resetAllFields()
				setAuthStatusMsg(`${data.data.message}. Zaboravili ste lozinku?`)

			} else {
				setBoxType('activation')
				resetAllFields()
				setAuthStatusMsg(`${data.data.message}. Zatraži novi aktivacijski link.`)

			}

		} catch (e) {
			setAuthStatusMsg(e.message)
			resetAllFields()
			setBoxType('email')
		}

		bruteForcePreventionProcedure()
	}

	const resendActivationMail = async () => {
		try {
			const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/resend-validation-link`, {
				email: userEmail
			})

			setAuthStatusMsg(data.data.message)
			resetAllFields()
			setBoxType('email')
		} catch (e) {
			setAuthStatusMsg(e.message)
		}
	}

	const resendLostPassMail = async () => {
		const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/lostpass`, {
			email: userEmail
		}, {
			headers: {
				'Content-Type': 'application/json'
			}
		})


		setAuthStatusMsg(data.data.message)
		resetAllFields()
		setBoxType('login')
	}

	const handleLoginRegisterSwitch = () => {
		if (boxType === 'login') {
			setBoxType('register')
			setAuthStatusMsg('Unesite korisničko ime')
			resetAllFields()
			return
		}

		if (boxType === 'register') {
			setBoxType('login')
			setAuthStatusMsg('Ulogiraj se u svoj acc')
			resetAllFields()
			return
		}

		if (boxType === 'email') {
			setBoxType('login')
			setAuthStatusMsg('Ulogiraj se u svoj acc')
			resetAllFields()
			return
		}

		if (boxType === 'activation') {
			setBoxType('login')
			setAuthStatusMsg('Ulogiraj se u svoj acc')
			resetAllFields()
		}
	}

	const handleChangeInfoActive = () => {
		if (boxType === 'imageBrowser') {
			setBoxType('login')
			handleToggleIimageBrowser()
			setDescVisible(true)
			return
		}

		setBoxType('imageBrowser')
		handleToggleIimageBrowser()
	}

	const handlePrevBtnClick = () => {
		handleChangeBgIndex(true, false)
	}

	const handleNextBtnClick = () => {
		handleChangeBgIndex(false, true)
	}

	const handleCloseModal = () => {
		setShowModal(false)
	}

	const handleOpenModal = () => {
		setShowModal(true)
	}

	const handleTownsChange = async (e) => {
		setTown(e.target.value)
		setLocBorder('MediumAquaMarine')
		setAuthStatusMsg('Dobro. Nastavi.')
	}

	const handleDateChange = (e) => {
		setBirthDate(e.target.value)
		setBorderColDate('MediumAquaMarine')
		setAuthStatusMsg('Dobro. Nastavi.')
	}

	const handleGenderChange = (e) => {
		setUserGender(e.target.value)
		setBorderColGender('MediumAquaMarine')
		setAuthStatusMsg('Dobro. Nastavi.')
	}

	const handleUserInputChange = async (e) => {
		setAuthStatusMsg('Unesi korisničko ime')
		setUserName(e.target.value)
		setLabelUser(e.target.value.length === 0 ? '' : '6-10 znakova')
		setBorderCol(e.target.value.length === 0 ? 'white' : 'orange')
		if (e.target.value.length >= 6 && e.target.value.length <= 10) {
			try {
				const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/testusername`, {
					name: e.target.value,
				}, {
					headers: {
						'Content-Type': 'application/json'
					}
				})

				setAuthStatusMsg(data.data.message)

				if (data.data.status === 1) {
					setBorderCol('MediumAquaMarine')
					setLabelUser('')
				}

			} catch (e) {
				setAuthStatusMsg(e.message)
				setBorderCol('orange')
				setLabelUser('')
			}
		}
		const timeout = setTimeout(() => {
			if (e.target.value.length === 0) {
				getRandomAvatar()
			} else {
				setAvatar(multiavatar(e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')))
			}

			clearTimeout(timeout)
		}, 2000)
	}

	const handlePassInputChange = (e) => {
		setPass(e.target.value)
		setLabelPass(e.target.value.length === 0 ? '' : 'Min. 8 znamenki, 1 veliko slovo, specijalni znak i 1 broj')
		setBorderColPass(e.target.value.length === 0 ? 'white' : 'orange')
		if (e.target.value.match(isGoodPassPattern) !== null) {
			setBorderColPass('MediumAquaMarine')
			setAuthStatusMsg('Lozinka je dobra. Nastavi.')
			setLabelPass('')
		}
	}

	function generatePassword() {
		const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const specialCharacters = "@#$%&";

		// Regex to validate the password
		const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).{8,}$/;

		let password;
		do {
			// Generate a random password
			password = "";
			const allCharacters = lowercaseLetters + uppercaseLetters + numbers + specialCharacters;

			// Ensure at least one character from each required set
			password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
			password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
			password += numbers[Math.floor(Math.random() * numbers.length)];
			password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

			// Add remaining characters to reach the minimum length
			for (let i = 4; i < 8; i++) {
				password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
			}

			// Shuffle the password to ensure randomness
			password = password.split("").sort(() => Math.random() - 0.5).join("");
		} while (!passwordRegex.test(password)); // Repeat until the password matches the regex




		setLabelPass(password === 0 ? '' : 'Min. 8 znamenki, 1 veliko slovo, specijalni znak i 1 broj')
		setBorderColPass(password === 0 ? 'white' : 'orange')
		if (password.match(isGoodPassPattern) !== null) {
			setBorderColPass('MediumAquaMarine')
			setAuthStatusMsg('Lozinka je dobra. Nastavi.')
			setLabelPass('')
		}


		setPass(password)
	}

	const handlePassInputChangeNoCheck = (e) => {
		setPass(e.target.value)
		setBorderColPass(e.target.value.length === 0 ? 'white' : 'orange')
		if (e.target.value.length >= 8) {
			setBorderColPass('MediumAquaMarine')
		}
	}

	const handleInputChange = async (e) => {
		//setAuthStatusMsg('Unesite email')
		setUserEmail(e.target.value)
		setLabelEmail(e.target.value.length === 0 ? '' : 'Unesite ispravnu email adresu')
		setBorderColEmail(e.target.value.length === 0 ? 'white' : 'orange')
		if (isEmailPattern.test(e.target.value) === true) {
			try {
				const data = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/testemail`, {
					email: e.target.value,
				}, {
					headers: {
						'Content-Type': 'application/json'
					}
				})

				setAuthStatusMsg(data.data.message)

				if (data.data.status === 1) {
					setBorderColEmail('MediumAquaMarine')
					setLabelEmail('')
				}

			} catch (e) {
				setAuthStatusMsg(e.message)
				setBorderColEmail('orange')
				setLabelEmail('')
			}

		}


	}

	const handleLoginInputChange = (e) => {
		//setAuthStatusMsg('Unesite email')
		setUserEmail(e.target.value)
		setLabelEmail(e.target.value.length === 0 ? '' : 'Unesite ispravnu email adresu')
		setBorderColEmail(e.target.value.length === 0 ? 'white' : 'orange')
		if (isEmailPattern.test(e.target.value) === true) {
			setBorderColEmail('MediumAquaMarine')
			setLabelEmail('')
		}


	}

	const handleSetCheckbox = () => {
		setCheckBox(!checkBox)
	}

	const handleLostPass = () => {
		setBoxType('email')
		setAuthStatusMsg('Upišite email adresu koju ste registrirali')
	}

	const checkFieldsLogin = () => {

		if (
			userEmail.length === 0 ||
			pass.length === 0
		) {
			return true
		}

		if (
			labelEmail.length === 0 &&
			labelPass.length === 0 &&
			checkBox
		) {
			return false
		}

		return true
	}

	const checkFieldsEmail = () => {

		if (
			userEmail.length === 0 && checkBox
		) {
			return true
		}

		if (
			labelEmail.length === 0 && checkBox
		) {
			return false
		}

		return true
	}

	const checkFields = () => {

		if (
			userName.length === 0 ||
			userEmail.length === 0 ||
			pass.length === 0 ||
			!birthDate ||
			!userGender ||
			town.length === 0
		) {
			return true
		}

		if (
			labelUser.length === 0 &&
			labelEmail.length === 0 &&
			labelPass.length === 0 &&
			birthDate &&
			userGender &&
			checkBox &&
			town.length !== 0
		) {
			return false
		}

		return true
	}

	const handleToggleDescVisible = () => {
		setDescVisible(!descVisible)
	}

	const generateRegisterComponent = () => {
		return (
			<div className="noselect login-box">
				<div className="logo-wrap">
					<img className="logo-home" src="logo.png" />
				</div>

				<div>
					<div className="w100 relative column center middle margin-bottom">
						<div className="avatar-img avatar-img-alt" dangerouslySetInnerHTML={{ __html: avatar }}></div>
						<img onClick={getRandomAvatar} className="refresh-avatar" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGElEQVR4nNXUQU7CUBDGceqCwNpwCI16AS4iaCKHQXFDVIh3MB7BM5gYo4JeQl240Yg/0zgkVSC8utJJmrSd1/+b+fq+qVT+bWADQ9zhJa78/gTrZUA1nOIDt9jHHjroBXSCAaopwB08YhvZnHyGNp5xsRQaH9QSNt7CUy7BogWrS8uf3837jKaoY4xmSWCGexz9TPR9xUMOLwk9xFXxRTP+2jT6iaAGznCJV5yjmye68fAWyXxRIxHaLxQy+SYZrnFQst16yDTbWThgVAZYkGw8o31YLT8CrV9AVxclBuGSzUSLZssWVcNOuQNaC6y3gt3YuJ1SfjUqncQg6MVg6MSZG0VumGLRSgG8huOYONPxdVN6fP25+ATGXqpmN1JjfwAAAABJRU5ErkJggg==" alt="refresh--v1" />
					</div>

					<div className="user-box">
						<p className="side-title noselect">{authStatusMsg}</p>
						<div className="margin-bottom w100">
							<p className="form-p margin-bottom-5">{`Korak ${regScreen}/7`}</p>
							<div className="row w100">
								<div onClick={() => { setRegScreenManualy(1) }} className={`step ${regScreen === 1 && 'step-current'} ${borderCol === 'MediumAquaMarine' ? 'step-done' : 'step-wrong'} pointer`}></div>
								<div onClick={() => { setRegScreenManualy(2) }} className={`step ${regScreen === 2 && 'step-current'} ${locBorder === 'MediumAquaMarine' ? 'step-done' : 'step-wrong'} pointer`}></div>
								<div onClick={() => { setRegScreenManualy(3) }} className={`step ${regScreen === 3 && 'step-current'} ${borderColEmail === 'MediumAquaMarine' ? 'step-done' : 'step-wrong'} pointer`}></div>
								<div onClick={() => { setRegScreenManualy(4) }} className={`step ${regScreen === 4 && 'step-current'} ${borderColPass === 'MediumAquaMarine' ? 'step-done' : 'step-wrong'} pointer`}></div>
								<div onClick={() => { setRegScreenManualy(5) }} className={`step ${regScreen === 5 && 'step-current'} ${borderColDate === 'MediumAquaMarine' ? 'step-done' : 'step-wrong'} pointer`}></div>
								<div onClick={() => { setRegScreenManualy(6) }} className={`step ${regScreen === 6 && 'step-current'} ${borderColGender === 'MediumAquaMarine' ? 'step-done' : 'step-wrong'} pointer`}></div>
								<div onClick={() => { setRegScreenManualy(7) }} className={`step ${regScreen === 7 && 'step-current'} ${checkBox && 'step-last'} pointer`}></div>
							</div>
						</div>
						<div className="w100 row margin-negative-top-7">
							{regScreen === 1 && <div className="w100 column p-right-5 ">
								<input type="text"
									minLength={6}
									maxLength={10}
									name="username"
									id='username'
									value={userName}
									onChange={handleUserInputChange}
									placeholder="Unesite nick"
									style={{ borderColor: borderCol }}
								/>
								{labelUser.length !== 0 && <label className="faded f12" htmlFor="email">{labelUser}</label>}
								{borderCol === 'MediumAquaMarine' && <p onClick={nextRegScreen} className="pointer form-p margin-top underline mediumaquamarine">{'Naredni korak >>'}</p>}
							</div>}
							{regScreen === 2 && <div className="w100 column">
								<select
									style={{ borderColor: locBorder }}
									onChange={handleTownsChange}
									value={town}
									className="mm-input faded f12"
									id="towns"
									name="towns"
								>
									<option key="defV2" value="" disabled selected>Odaberi lokaciju</option>
									{towns.map(town => <option key={town} value={town}>{town}</option>)}
								</select>
								<div className="row margin-top">
									<p onClick={prevRegScreen} className="pointer form-p underline">{'<< Povratak'}</p>
									{locBorder === 'MediumAquaMarine' && <p onClick={nextRegScreen} className="pointer form-p margin-left underline mediumaquamarine">{'Nastavi >>'}</p>}
								</div>
							</div>}
						</div>

						{regScreen === 3 && <div>
							<input type="email"
								name="email"
								id='email'
								value={userEmail}
								onChange={handleInputChange}
								placeholder="Unesite email"
								style={{ borderColor: borderColEmail }}
							/>
							{labelEmail.length !== 0 && <label className="faded f12" htmlFor="email">{labelEmail}</label>}
							<div className="row margin-top">
								<p onClick={prevRegScreen} className="pointer form-p underline">{'<< Povratak'}</p>
								{borderColEmail === 'MediumAquaMarine' && <p onClick={nextRegScreen} className="pointer form-p margin-left underline mediumaquamarine">{'Nastavi >>'}</p>}
							</div>
						</div>}


						{regScreen === 4 && <div className="relative">
							<input type={showPassword ? "text" : "password"}
								minLength={8}
								name="pass"
								id='pass'
								value={pass}
								placeholder="Unesite lozinku"
								onChange={handlePassInputChange}
								style={{ borderColor: borderColPass }}
							/>
							{labelPass.length !== 0 && <label className="faded f12" htmlFor="email">{labelPass}</label>}
							<img
								style={{
									position: "absolute",
									right: "10px",
									top: "15px",

									cursor: "pointer"
								}}
								onClick={togglePasswordVisibility}
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVR4nJ3Svy6EURAF8C8kCsUWbGFlKyRsRzYr0fjT0FJYFQ3xChIegMQqSNjQbSXBG9DgUehUKlH95MZI1pcl1k0mc2fOOXfu3LlZlmUZihjpwopZCBfQwkEX1sJ8Em+jmnWxUE26b2L05UgdY3kxKnhDPQhzeMdsxGuBVzqJCzjDeJCH0Uw+4onACx2v/Z+et1DLgWOR3wk/msNr2Py63hH2MI1rPGE1ZlrHM64wgwZOUWo/bSV62scklhIxsKnIN7Gc72ERh7G/CP+C1/QpcvlG4reLy7jBYCKhB48+1z16cR544pXz1Uu4xDF20Z9mHD69x0ngQz+NIFXYiKp3qRXc4gHrCf/rLNNBA78JPgDKVfDOuhH8XgAAAABJRU5ErkJggg==" alt="show-password" />

							<img
								onClick={generatePassword}
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVR4nK3SsUtCURgF8Iegk40RBA3RpmCQoLS0tTREQ1vYFDa15CIuLo1CS4pN0doSBO39Z7+4eB881J7vYR+c5d5z7jn33Jsk2w4qeMbrBkxRWxYf4xFXATkmPZwvL3ZwlzrkiM/wiYPS4jDoo/1vzi0McBGQI75dd+fQ9qRA2y+oJmUHXVzjqIxoH+94wA0+sJcl1PD0R9SflGwRe6XtBt6wi2aIlsbDPMObr4jjxiVmsbjwDQOC8zdOI2eEe5wUvXMV4xh5iC/sFC4tHlLHYfapfgECT2rIOllQlwAAAABJRU5ErkJggg=="
								style={{
									position: "absolute",
									right: "35px",
									top: "15px",

									cursor: "pointer"
								}}
								alt="lock-2" />

							<div className="row margin-top">
								<p onClick={prevRegScreen} className="pointer form-p  underline">{'<< Povratak'}</p>
								{borderColPass === 'MediumAquaMarine' && <p onClick={nextRegScreen} className="pointer form-p margin-left underline mediumaquamarine">{'Nastavi >>'}</p>}
							</div>
						</div>}

						<div className="w100 row margin-top-5">
							{regScreen === 5 && <div className="w100 column p-right-5 date-container">
								<input
									value={birthDate}
									onChange={handleDateChange}
									type="date"
									className="faded f12"
									placeholder="Datum"
									style={{ borderColor: borderColDate }}
								/>
								<p className="fw200 faded f12 margin-top-s" htmlFor="age-to">Datum rođenja</p>
								<div className="row margin-top">
									<p onClick={prevRegScreen} className="pointer form-p  underline">{'<< Povratak'}</p>
									{borderColDate === 'MediumAquaMarine' && <p onClick={nextRegScreen} className="pointer form-p margin-left underline mediumaquamarine">{'Nastavi >>'}</p>}
								</div>
							</div>}
							{regScreen === 6 && <div className="w100 column right">
								<select
									style={{ borderColor: borderColGender }}
									value={userGender}
									onChange={handleGenderChange}
									className="mm-input faded f12"
									id="age-to"
									name="age-to"
								>
									<option className="faded f12" disabled selected value="">Odaberi spol</option>
									<option className="faded f12" value="M">Muško</option>
									<option className="faded f12" value="Ž">Žensko</option>
									<option className="faded f12" value="N">Nebinaran</option>
								</select>
								<label className="fw200 faded f12 margin-top-s" htmlFor="age-to">Spol</label>
								<div className="row margin-top">
									<p onClick={prevRegScreen} className="pointer form-p  underline">{'<< Povratak'}</p>
									{borderColGender === 'MediumAquaMarine' && <p onClick={nextRegScreen} className="pointer form-p margin-left underline mediumaquamarine">{'Nastavi >>'}</p>}
								</div>
							</div>}
						</div>

						{regScreen === 7 && <div>
							<div className="checkbox-wrap margin-top-s">
								<input
									type="checkbox"
									id="checkbox"
									name="checkbox"
									checked={checkBox}
									onChange={handleSetCheckbox}
								/>

								<label htmlFor="checkbox" className="margin-left form-p noselect">Pročitao sam <span onClick={handleOpenModal}>uvodnik autora</span></label>
							</div>
							{checkFields() && <p onClick={prevRegScreen} className="pointer form-p margin-top margin-bottom underline">{'<< Povratak'}</p>}
							{!checkFields() && <p onClick={prevRegScreen} className="pointer form-p margin-top margin-bottom underline">{'<< Želite ispravit podatke?'}</p>}
						</div>
						}

					</div>



					<div className="row middle">
						{!checkFields() && <button onClick={handleSubmit} disabled={checkFields()} className="content-button noselect margin-right">
							Registriraj me
						</button>}

						<p onClick={handleLoginRegisterSwitch} className="form-p underline pointer noselect">Već ste se registrirali? Ulogirajte se!</p>
					</div>

				</div>
			</div>
		)
	}

	const generateLoginComponent = () => {

		return (
			<div className="noselect login-box">
				<div className="logo-wrap"><img className="logo-home" src="logo.png" /></div>

				<div>
					<div className="w100 column center middle margin-bottom">
						<div className="avatar-img avatar-img-alt" dangerouslySetInnerHTML={{ __html: avatar }}></div>
					</div>

					<div className="user-box">
						<p className="side-title noselect">{authStatusMsg}</p>

						<input type="email"
							name="email"
							id='email'
							value={userEmail}
							onChange={handleLoginInputChange}
							placeholder="Unesite email"
							style={{ borderColor: borderColEmail }}
						/>
						{labelEmail.length !== 0 && <label className="faded f12" htmlFor="email">{labelEmail}</label>}

						<input type="password"
							minLength={8}
							name="pass"
							id='pass'
							value={pass}
							placeholder="Unesite lozinku"
							onChange={handlePassInputChangeNoCheck}
							style={{ borderColor: borderColPass }}
						/>
						{labelPass.length !== 0 && <label className="faded f12" htmlFor="email">{labelPass}</label>}
						<p className="form-p underline margin-top pointer"><span onClick={handleLostPass}>Zaboravili ste lozinku?</span></p>

						<div className="checkbox-wrap margin-top">
							<input
								type="checkbox"
								id="checkbox"
								name="checkbox"
								checked={checkBox}
								onChange={handleSetCheckbox}
							/>
							<label htmlFor="checkbox" className="margin-left form-p noselect">Pročitao sam <span onClick={handleOpenModal}>uvodnik autora</span></label>
						</div>
					</div>


					<div className="row">
						{!submitBtnDisabled ? <button onClick={handleLoginSubmit} type="button" disabled={checkFieldsLogin()} className="content-button noselect">Login</button> : <p className="form-p">{`Pokušaj opet za ${loginAttempts * 4 / 1000} sec.`}</p>}
						<a onClick={handleLoginRegisterSwitch} className="pointer form-p margin-left margin-top-s underline">
							Niste se registrirali?
						</a>

					</div>

				</div>
			</div>


		)
	}

	const generateEmailComponent = () => {
		return (
			<div className="noselect login-box">
				<div className="logo-wrap"><img className="logo-home" src="logo.png" /></div>

				<div>
					<div className="w100 column center middle margin-bottom">
						<div className="avatar-img avatar-img-alt" dangerouslySetInnerHTML={{ __html: avatar }}></div>
					</div>

					<div className="user-box">
						<p className="side-title noselect">{authStatusMsg}</p>
						<input type="email"
							name="email"
							id='email'
							value={userEmail}
							onChange={handleLoginInputChange}
							placeholder="Unesite email"
							style={{ borderColor: borderColEmail }}
						/>
						{labelEmail.length !== 0 && <label className="faded f12" htmlFor="email">{labelEmail}</label>}

					</div>

					<div className="checkbox-wrap margin-bottom margin-negative-top-20">
						<input
							type="checkbox"
							id="checkbox"
							name="checkbox"
							checked={checkBox}
							onChange={handleSetCheckbox}
						/>
						<label htmlFor="checkbox" className="margin-left form-p noselect">Pročitao sam <span onClick={handleOpenModal}>uvodnik autora</span></label>
					</div>


					<div className="row middle">
						<button onClick={resendLostPassMail} type="button" disabled={checkFieldsEmail()} className="content-button margin-right noselect">Zatraži</button>
						<p onClick={handleLoginRegisterSwitch} className="margin-left form-p underline pointer noselect">
							Povratak na login
						</p>

					</div>

				</div>
			</div>
		)
	}

	const generateActivationComponent = () => {
		return (
			<div className="noselect login-box">
				<div className="logo-wrap"><img className="logo-home" src="logo.png" /></div>

				<div>
					<div className="w100 column center middle">
						<div className="avatar-img avatar-img-alt" dangerouslySetInnerHTML={{ __html: avatar }}></div>
					</div>

					<div className="user-box">
						<p className="side-title noselect">{authStatusMsg}</p>
						<input type="email"
							name="email"
							id='email'
							value={userEmail}
							onChange={handleLoginInputChange}
							placeholder="Unesite email"
							style={{ borderColor: borderColEmail }}
						/>
						{labelEmail.length !== 0 && <label className="faded f12" htmlFor="email">{labelEmail}</label>}

					</div>

					<div className="checkbox-wrap margin-bottom margin-negative-top-20">
						<input
							type="checkbox"
							id="checkbox"
							name="checkbox"
							checked={checkBox}
							onChange={handleSetCheckbox}
						/>
						<label htmlFor="checkbox" className="margin-left form-p noselect">Pročitao sam <span onClick={handleOpenModal}>uvodnik autora</span></label>
					</div>


					<div className="row middle">
						<button onClick={resendActivationMail} type="button" disabled={checkFieldsEmail()} className="content-button noselect">Zatraži</button>
						<a onClick={handleLoginRegisterSwitch} className="form-p underline pointer noselect margin-left">
							Povratak na login
						</a>

					</div>

				</div>
			</div>
		)
	}

	return (
		<div>
			{!bgs[bgIndex] ?
				<div className="video-bg" style={{ backgroundColor: '#333' }}></div> :
				<div className="video-bg" style={{ backgroundImage: `url(${bgs[bgIndex].url})` }}></div>
			}
			{boxType === 'register' && generateRegisterComponent()}
			{boxType === 'login' && generateLoginComponent()}
			{boxType === 'email' && generateEmailComponent()}
			{boxType === 'activation' && generateActivationComponent()}
			{sub !== 'noImage' && <div className="image-info-wrapper">
				{boxType === 'imageBrowser' && descVisible && <div className="image-info-sub relative">
					<div
						onClick={handleToggleDescVisible}
						className="close-desc pointer">x</div>
					{name && <h3 className="image-info-name">{name}</h3>}
					{sub && <p>{sub}</p>}
					{link && <a href={link} target="_blank" className="noselect content-button">Izvor</a>}
				</div>}
				<div className="image-info-content">
					{!descVisible && <div onClick={handleToggleDescVisible} className="image-info-icon noselect">
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAw0lEQVR4nO3ZQQ7CMAxE0ay4E+V8ULhnwzU+SpUFys5qnVjpvH0kj0bOximJyHUBN+ADfIkjA2uZzRLkTVwvS5CSvrinIIClzpQtj3YpGKxzKYgz1Eij17eUvBuZJshoKEgwqJGG5yJ3bYRZgoyGggSDGnFY9iMfAmc1Mk2Q0VCQYFAjDc9F7toIswQZDQUJBjXisOxHPgTOamSaIKOhIMFw5UZyfbOkIIBHnWmzPCrX06ie1vP0+tdMBFsJYTpPi8h8frKzPJ6UEBbCAAAAAElFTkSuQmCC" />
					</div>}

					<div onClick={handleChangeInfoActive} className="noselect image-info-icon">
						{boxType === 'imageBrowser' ?
							<img className="noselect" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAt0lEQVR4nO2VQQ6AIAwE9//P0viu9d6EpECrBXZuHqydLkVACNEFyYf1uT0iSwCvCIpCiRSDSuTURJh8O1EinURd38xKxHsEJGJglR0Zfb+cSBSQyGQi2dDbn0Q+gkpkcGJRoFHf3UivwLYimIRZIt7JScRAHa3gHYkCjfruRo4XyYZfJZINJVIM/r3so/UsEpmdWOt5mf8IdhGxjNazuOvNfngbkWwokdUTqQ4cIjfrcwUFLMQxvHo1VjklK40CAAAAAElFTkSuQmCC" /> :
							<img src="https://img.icons8.com/ios/50/FFFFFF/info-popup.png" />
						}
					</div>
					<div onClick={handlePrevBtnClick} className="image-info-btn image-info-btn-prev">
						<a className="noselect">{'<'}</a>
					</div>
					<div onClick={handleNextBtnClick} className="image-info-btn">
						<a className="noselect">{'>'}</a>
					</div>
				</div>

			</div>}
			{boxType === 'imageBrowser' && <img src="logo.png" className="onimageactivelogo noselect" />}

			<div className="appsdwnld-wrap">
				<img className="noselect" src="playstore.png" />
				<img className="noselect" src="gplay.png" />
				<img className="mobapp-ico noselect" src="playstore-alt.png" />
				<img className="mobapp-ico noselect" src="gplay-alt.png" />
			</div>
			{showModal && <Modal currUser={userName} handleCloseModal={handleCloseModal} socket={socket} title='Uvjeti korištenja' type="uvjetiKoristenja" />}
		</div>
	)
}

export default Home