import React from 'react'
import axios from 'axios'
import Box from './Box'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const levels = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
    60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130,
    135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195,
    200, 205, 210, 215, 220, 225, 230, 235, 240, 245, 250, 260, 270, 280,
    290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420,
    430, 440, 450, 470, 490, 510, 530, 550, 570, 590, 610, 630, 650, 670, 690,
    710, 730, 750, 770, 790, 810, 830, 850, 880, 910, 940, 970, 1000, 1130, 1160, 1190
]

const isEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
const isGoodPassPattern = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/

const maxLengths = {
    statusMessage: 59,
    about: 300,
    requests: 300,
    hobiji: 300,
    filmovi: 300,
    serije: 300,
    knjige: 300,
    citati: 300,
    muzika: 300
}

const minLenghts = {
    statusMessage: 10,
    about: 70,
    requests: 70,
    hobiji: 70,
    filmovi: 70,
    serije: 70,
    knjige: 70,
    citati: 70,
    muzika: 70
}

const inputColorsDefault = {
    location: '',
    statusMessage: '',
    about: '',
    requests: '',
    hobiji: '',
    filmovi: '',
    serije: '',
    knjige: '',
    citati: '',
    muzika: '',
    avatar: '',
    foto: ''
}

const inputLabelsDefault = {
    location: 'Lokacija',
    statusMessage: 'Statusna poruka',
    about: 'O meni',
    requests: 'Zahtjevi/što tražiš',
    hobiji: 'Hobiji',
    filmovi: 'Filmovi',
    serije: 'Serije',
    knjige: 'Knjige',
    citati: 'Citati',
    muzika: 'Muzika',
    avatar: 'Avatar',
    foto: 'Dodaj sliku u galeriju'
}

const btnDisabledDefaults = {
    location: false,
    avatar: false
}

const emailDefaults = {
    value: '',
    color: '',
    lable: ''
}

const SingleUser = ({
    generateHeaderCloseIco,
    towns,
    currSingleUserName,
    setType,
    currUser,
    joinRoom,
    me,
    socket,
    handleKickUser,
    getNews,
    setCurrSingleNews,
    newsList,
    handleSetIsEditing
}) => {

    const [currSingleUser, setCurrSingleUser] = React.useState(null)
    const [inputColors, setInputColors] = React.useState(inputColorsDefault)
    const [inputLabels, setInputLabels] = React.useState(inputLabelsDefault)
    const [infoMessage, setInfoMessage] = React.useState('')
    const [btnDisabled, setBtnDisabled] = React.useState(btnDisabledDefaults)
    const [checkBox, setCheckBox] = React.useState(false)
    const [email, setEmail] = React.useState(emailDefaults)
    const [inputVisibility, setInputVisibility] = React.useState(false)
    const [adminInfoMsg, setAdminInfoMsg] = React.useState('')
    const [adminInfoMsgBottom, setAdminInfoMsgBottom] = React.useState('')
    const [currGalFoto, setCurrGalFoto] = React.useState('')
    const [currGalFotoIndex, setCurrGalFotoIndex] = React.useState(0)
    const [passVisibility, setPassVisibility] = React.useState(false)
    const [imgVisibility, setImgVisibility] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const [pass, setPass] = React.useState('')
    const [labelPass, setLabelPass] = React.useState('')
    const [borderColPass, setBorderColPass] = React.useState('')
    const [userNewsList, setUserNewsList] = React.useState([])
    const [userSongs, setUserSongs] = React.useState([])
    const [suspensionDays, setSuspensionDays] = React.useState(1)

    function generatePassword() {
        const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
        const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        const specialCharacters = "@#$%&";

        // Regex to validate the password
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).{8,}$/;

        let password;
        do {
            // Generate a random password
            password = "";
            const allCharacters = lowercaseLetters + uppercaseLetters + numbers + specialCharacters;

            // Ensure at least one character from each required set
            password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
            password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
            password += numbers[Math.floor(Math.random() * numbers.length)];
            password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

            // Add remaining characters to reach the minimum length
            for (let i = 4; i < 8; i++) {
                password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
            }

            // Shuffle the password to ensure randomness
            password = password.split("").sort(() => Math.random() - 0.5).join("");
        } while (!passwordRegex.test(password)); // Repeat until the password matches the regex




        setLabelPass(password === 0 ? '' : 'Min. 8 znamenki, 1 veliko slovo, specijalni znak i 1 broj')
        setBorderColPass(password === 0 ? 'white' : 'orange')
        if (password.match(isGoodPassPattern) !== null) {
            setBorderColPass('MediumAquaMarine')
            setLabelPass('')
        }


        setPass(password)
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleDeleteSong = async (id) => {
        const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/song-by-id/${id}`)
        if (response.data.status === 1) {
            socket.emit('deleteSong')
        }
    }

    const handlePassInputChange = (e) => {
        setPass(e.target.value)
        setLabelPass(e.target.value.length === 0 ? '' : 'Min. 8 znamenki, 1 veliko slovo, specijalni znak i 1 broj')
        setBorderColPass(e.target.value.length === 0 ? 'white' : 'orange')
        if (e.target.value.match(isGoodPassPattern) !== null) {
            setBorderColPass('MediumAquaMarine')
            setAdminInfoMsg('Lozinka je dobra. Nastavi.')
            setLabelPass('')
        }
    }

    const handleSuspensionDaysChange = (e) => {
        setSuspensionDays(e.target.value)
    }

    const handleStartUserSuspension = async () => {
        const currentDate = new Date();
        const newDate = new Date(currentDate.getTime() + suspensionDays * 24 * 60 * 60 * 1000);
        const response = await axios.put(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/suspend-user/${currSingleUser.name}`,
            {
                currentDate: newDate.toISOString()
            },
            {
                headers: {
                    'authorization': localStorage.getItem('token')
                }
            })

        if (response.data.status === 1) {
            setInfoMessage(response.data.message)
            handleKickUser(currSingleUserName)
        } else {
            setInfoMessage(response.data.message)
        }   

    }


    React.useEffect(() => {
        const handleUpdateNews = () => {
            getUsersNews()
        }
        socket.on('updateNews', handleUpdateNews)

        return () => {
            socket.off('updateNews', handleUpdateNews)
        }
    }, [socket, userNewsList])


    React.useEffect(() => {

        const handleDeleteUserRequestResponse = responseMsg => {
            setAdminInfoMsgBottom(responseMsg)
            setEmail(emailDefaults)
            setInputVisibility(false)
        }

        const handleUpdateUserSongs = responseMsg => {
            getUserSongs()
        }


        socket.on('delete-user-request-response', handleDeleteUserRequestResponse)
        socket.on('updateSongs', handleUpdateUserSongs)

        return () => {
            socket.off('delete-user-request-response', handleDeleteUserRequestResponse)
            socket.off('updateSongs', handleUpdateUserSongs)
        }
    }, [socket])

    React.useEffect(() => {
        getUser()
        getUsersNews()
        getUserSongs()
    }, [])

    React.useEffect(() => {
        const handleGetUser = () => {
            getUser()
        }

        socket.on('rifreshUser', handleGetUser)

        return () => {
            socket.off('rifreshUser', handleGetUser)
        }

    }, [socket])



    const handlePrevImg = () => {
        if (currGalFotoIndex === 0) {
            setCurrGalFotoIndex(currSingleUser.galerija.length - 1)
            return
        }
        setCurrGalFotoIndex(currGalFotoIndex - 1)
    }

    const handleNextImg = () => {
        if (currGalFotoIndex === currSingleUser.galerija.length - 1) {
            setCurrGalFotoIndex(0)
            return
        }
        setCurrGalFotoIndex(currGalFotoIndex + 1)
    }

    const handleRemoveFoto = async (photoToDelete) => {
        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/update-galery-delete/${currSingleUserName}`, {
                photoToDelete
            })
            if (response.data.status === 1) {
                setInfoMessage('Slika obrisana')
                getUser()
            }
        } catch (e) {
            setInfoMessage(e.message)
        }
    }

    const getUsersNews = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/news/users/${currSingleUserName}`)
            if (response.data.status === 1) {
                setUserNewsList(response.data.news)
            }
        } catch (e) {
            setInfoMessage(e.message)
        }
    }

    const getUserSongs = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/songs/${currSingleUserName}`)
            if (response.data.status === 1) {
                setUserSongs(response.data.songs)
            }
        } catch (e) {
            setInfoMessage(e.message)
        }
    }

    const renderNews = () => {

        return userNewsList.map((news) => {
            return <div key={`${Math.random()}`} className="margin-top">
                <Box handleSetIsEditing={handleSetIsEditing} socket={socket} currUser={currUser} getNews={getNews} setCurrSingleNews={setCurrSingleNews} setType={setType} isInModal={true} key={`${Math.random()}${setUserNewsList.length}`} news={news} showBtn={true} />
            </div>
        })
    }

    const renderSongs = () => {
        return (
            <div>
                {userSongs.map((song) => {
                    const url = song.link
                    const videoId = new URL(url).searchParams.get("v");
                    return (
                        <div className="relative w100">
                            {showDeleteIco(song.author) && <span
                                onClick={() => { handleDeleteSong(song.id) }}
                                className="notification-number-alt logout-ico pointer">
                                x
                            </span>}
                            <div className="margin-right margin-bottom" style={{ borderRadius: '20px', position: 'relative', paddingBottom: '56.25%', height: '0', overflow: 'hidden', maxWidth: '100%', width: '100%' }}>
                                <iframe src={`https://www.youtube.com/embed/${videoId}`} frameborder="0" allowfullscreen
                                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe>
                            </div>
                        </div>

                    )
                })}
            </div>
        )
    }

    const handleAddFoto = async () => {
        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/update-galery/${currSingleUserName}`, {
                currGalFoto
            })
            if (response.data.status === 1) {
                setInfoMessage('Slika je dodana')
                getUser()
                setInputLabels({ ...inputLabels, foto: '' })
                setCurrGalFoto('')
            }
        } catch (e) {
            setInfoMessage(e.message)
        }
    }

    const handleFotoChange = (e) => {
        setCurrGalFoto(e.target.value)
        setInfoMessage('')
        if (e.target.value.length === 0) {
            setInputColors({ ...inputColors, foto: '' })
            setInputLabels({ ...inputLabels, foto: '' })
        } else if (!checkImage(e.target.value)) {
            setInputColors({ ...inputColors, foto: 'orange' })
            setInputLabels({ ...inputLabels, foto: 'Link na sliku nije dobar' })
        } else if (checkImage(e.target.value)) {
            setInputColors({ ...inputColors, foto: 'MediumAquaMarine' })
            setInputLabels({ ...inputLabels, foto: 'Bravo. Možeš dodati sliku.' })
        }
    }

    const checkImage = (inputValue) => {
        const imagePattern = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;

        if (imagePattern.test(inputValue)) {
            return true
        }
        return false
    }

    const handleImageChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, avatar: e.target.value })
        if (e.target.value.length === 0) {
            setInputColors({ ...inputColors, avatar: '' })
            setInputLabels({ ...inputLabels, avatar: '' })
            setBtnDisabled({ ...btnDisabled, avatar: true })
        } else if (!checkImage(e.target.value)) {
            setInputColors({ ...inputColors, avatar: 'orange' })
            setInputLabels({ ...inputLabels, avatar: 'Link na sliku nije dobar' })
            setBtnDisabled({ ...btnDisabled, avatar: true })
        } else if (checkImage(e.target.value)) {
            setInputColors({ ...inputColors, avatar: 'MediumAquaMarine' })
            setInputLabels({ ...inputLabels, avatar: '' })
            setBtnDisabled({ ...btnDisabled, avatar: false })
        }
    }

    const resendLostPassMail = async () => {
        try {
            const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/changepass`, {
                email: currSingleUser.email,
                pass
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.status === 1) {
                setAdminInfoMsg(response.data.message)
                setPassVisibility(false)
                const startKicking = setTimeout(() => {
                    clearTimeout(startKicking)
                    handleKickUser(currSingleUser.name)
                }, 3000)
            } else {
                setAdminInfoMsg(response.data.message)
            }

        } catch (e) {
            setAdminInfoMsg(e.message)
        }

    }

    const updateUserEmail = async () => {
        try {
            const response = await axios.put(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/update-user-email/${currSingleUser.id}`, {
                email: email.value
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.status === 1) {
                setAdminInfoMsg(response.data.message)
                setInputVisibility(!inputVisibility)
                setEmail(emailDefaults)
                const startKicking = setTimeout(() => {
                    clearTimeout(startKicking)
                    handleKickUser(currSingleUser.name)
                }, 3000)
            } else {
                setAdminInfoMsg(response.data.message)
                setEmail(emailDefaults)
                setInputVisibility(!inputVisibility)
            }

        } catch (e) {
            setAdminInfoMsg(e.message)
        }

    }

    const updateUserImg = async () => {
        setAdminInfoMsg('Samo sekund, čeeka')
        try {
            const response = await axios.put(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/update-user-avatar/${currSingleUser.name}`, {
                avatar: currSingleUser.avatar
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.status === 1) {
                setAdminInfoMsg(response.data.message)
                setImgVisibility(!imgVisibility)
                socket.emit('rifreshAll', currSingleUser.name, currSingleUser.avatar)

            } else {
                setAdminInfoMsg(response.data.message)
                setImgVisibility(!imgVisibility)
            }

        } catch (e) {
            setAdminInfoMsg(e.message)
        }

    }

    const getUser = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/user-name/${currSingleUserName}`, {
                attributes: ['pmQueue', 'avatar', 'name', 'level', 'location', 'statusMessage', 'birthDate', 'gender', 'about', 'requests', 'hobiji', 'filmovi', 'serije', 'knjige', 'citati']
            })
            if (response.data.status === 1) {
                setCurrSingleUser(response.data.user)
                setInputColors({
                    location: response.data.user.location ? 'orange' : '',
                    statusMessage: response.data.user.statusMessage ? 'orange' : '',
                    about: response.data.user.about ? 'orange' : '',
                    requests: response.data.user.requests ? 'orange' : '',
                    hobiji: response.data.user.hobiji ? 'orange' : '',
                    filmovi: response.data.user.filmovi ? 'orange' : '',
                    serije: response.data.user.serije ? 'orange' : '',
                    knjige: response.data.user.knjige ? 'orange' : '',
                    citati: response.data.user.citati ? 'orange' : '',
                    muzika: response.data.user.muzika ? 'orange' : '',
                    avatar: 'orange'
                })
                setInputLabels({
                    location: response.data.user.location ? 'Lokacija' : '',
                    statusMessage: response.data.user.statusMessage ? 'Statusna poruka' : '',
                    about: response.data.user.about ? 'O meni' : '',
                    requests: response.data.user.requests ? 'Zahtjevi/što tražiš' : '',
                    hobiji: response.data.user.hobiji ? 'Hobiji' : '',
                    filmovi: response.data.user.filmovi ? 'Filmovi' : '',
                    serije: response.data.user.serije ? 'Serije' : '',
                    knjige: response.data.user.knjige ? 'Knjige' : '',
                    citati: response.data.user.citati ? 'Citati' : '',
                    muzika: response.data.user.muzika ? 'Omiljena muzika' : '',
                    avatar: 'Avatar'
                })
            }
        } catch (e) {

        }
    }

    const updateUser = async () => {
        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/update-user-name/${currSingleUserName}`, currSingleUser)
            if (response.data.status === 1) {
                setInfoMessage('Profil ažuriran')
            }
        } catch (e) {

        }
    }

    const handleDeleteUser = () => {
        socket.emit('delete-user-request', currSingleUser.name)
    }

    const showDeleteIco = (author) => {
        console.log('author', author)
        if (currUser === author) {
            return true
        }

        if (currUser === 'TheCreator') {
            return true
        }

        if (currUser === 'Superadmin') {
            return true
        }

        return false
    }

    const handlePMUser = () => {
        const pastPMIndex = currSingleUser.pmQueue.findIndex(pm => pm.from === currUser)
        const myPastPMIndex = me.pmQueue.findIndex(pm => pm.from === currSingleUser.name)
        if (pastPMIndex !== -1) {
            joinRoom({ ...currSingleUser.pmQueue[pastPMIndex], from: currSingleUser.name })
        } else if (myPastPMIndex !== -1) {
            joinRoom({ ...me.pmQueue[myPastPMIndex], from: currSingleUser.name })
        } else {
            joinRoom({
                from: currSingleUser.name,
                room: `${currUser}${Math.random()}`
            })
        }
    }

    const checkFields = (inputName, inputValue) => {
        if (
            inputValue.length <= maxLengths[inputName] &&
            inputValue.length >= minLenghts[inputName]
        ) {
            return true
        }
        return false

    }

    const checkEmptyFields = (inputValue) => {
        if (inputValue.length === 0) {
            return true
        }
        return false
    }

    const handleSetBorderColorAndLabel = (inputName, inputValue, frontName) => {

        let inputBorderColor = ''
        let inputLabel = ``
        setInfoMessage('')
        setCheckBox(false)

        if (checkFields(inputName, inputValue)) {
            inputBorderColor = 'mediumaquamarine'
            inputLabel = `Odlično. ${frontName} ne smije prelaziti ${maxLengths[inputName]} znakova`
        } else if (!checkFields(inputName, inputValue)) {
            inputBorderColor = 'orange'
            inputLabel = `${frontName} mora imati minimalno ${minLenghts[inputName]} znakova`
        }

        if (checkEmptyFields(inputValue)) {
            inputBorderColor = ''
            inputLabel = ``
        }

        setInputColors({ ...inputColors, [inputName]: inputBorderColor })
        setInputLabels({ ...inputLabels, [inputName]: inputLabel })
    }

    const handleTownsChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, location: e.target.value })
        setInputColors({ ...inputColors, location: 'mediumaquamarine' })
    }

    const handleStatusChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, statusMessage: e.target.value })
        handleSetBorderColorAndLabel('statusMessage', e.target.value, 'Status')
    }

    const handleAboutChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, about: e.target.value })
        handleSetBorderColorAndLabel('about', e.target.value, 'Tekst o tebi')
    }

    const handleRequestChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, requests: e.target.value })
        handleSetBorderColorAndLabel('requests', e.target.value, 'Dio o zahtjevima')
    }

    const handleHobijiChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, hobiji: e.target.value })
        handleSetBorderColorAndLabel('hobiji', e.target.value, 'Tekst o hobijima')
    }

    const handleMuzikaChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, muzika: e.target.value })
        handleSetBorderColorAndLabel('muzika', e.target.value, 'Omiljene pjesme/bendovi')
    }

    const handleFilmoviChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, filmovi: e.target.value })
        handleSetBorderColorAndLabel('filmovi', e.target.value, 'Pasus o filmovima')
    }

    const handleSerijeChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, serije: e.target.value })
        handleSetBorderColorAndLabel('serije', e.target.value, 'Odjeljak o serijama')
    }

    const handleKnjigeChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, knjige: e.target.value })
        handleSetBorderColorAndLabel('knjige', e.target.value, 'O knjigama odjeljak')
    }

    const handleCitatiChange = (e) => {
        setCurrSingleUser({ ...currSingleUser, citati: e.target.value })
        handleSetBorderColorAndLabel('citati', e.target.value, 'Citat')
    }

    const handleSetCheckbox = () => {
        setCheckBox(!checkBox)
        setInfoMessage('')
    }

    const handleOpenTermOfUse = () => {
        setType('uvjetiKoristenja')
    }

    const handleStartKickingUser = () => {
        handleKickUser(currSingleUserName)
    }

    const handleEmailChange = (e) => {

        setEmail({
            value: e.target.value,
            label: e.target.value.length === 0 ? '' : 'Unesite ispravnu email adresu',
            color: e.target.value.length === 0 ? 'white' : 'orange'
        })

        if (isEmailPattern.test(e.target.value) === true) {
            setEmail({
                value: e.target.value,
                label: 'Email adresa je ispravna.',
                color: 'MediumAquaMarine'
            })
        }
    }


    const handleToggleEmailInput = () => {
        setInputVisibility(!inputVisibility)
        setAdminInfoMsg('')
        setEmail(emailDefaults)
    }

    const handleTogglePassInput = () => {
        setPassVisibility(!passVisibility)
        setAdminInfoMsg('')
    }

    const handleToggleImgInput = () => {
        setImgVisibility(!imgVisibility)
        setAdminInfoMsg('')
    }

    const getAgeFromDate = (ageToFormat) => {
        const givenDate = new Date(ageToFormat);
        const currentDate = new Date();
        const timeDiff = currentDate - givenDate;
        const years = timeDiff / (1000 * 60 * 60 * 24 * 365.25);
        return Math.round(years)
    }

    const checkIsBtnDisabled = () => {
        if (
            !checkBox || Object.values(btnDisabled).includes(true)
        ) {
            return true
        }

        return false
    }

    const formatDateToNormal = (dateToReformat) => {
        const dateObject = new Date(dateToReformat);
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        return formattedDate
    }

    const goBack = () => {
        setType('korisnici')
    }

    const startDeletingUser = async () => {
        try {
            const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/delete-user/${currSingleUser.name}`, {
                headers: {
                    'authorization': localStorage.getItem('token')
                }
            })

            if (response.data.status === 1) {
                setInfoMessage('User deleted')
                socket.emit('rifreshAll', currSingleUser.name, currSingleUser.avatar)
                handleKickUser(currSingleUser.name)
            } else {
                setInfoMessage(response.data.message)
            }
        } catch (e) {
            setInfoMessage('Nešto je pošlo po zlu')
        }
    }

    const generateNextLevelIn = () => {
        const nextLevelIndex = levels.findIndex(lvl => lvl > currSingleUser.wins)
        return currSingleUser.wins === 0 ? 'Idući level za 1 multiplayer pobjedu' : `Idući level: ${levels[nextLevelIndex] - currSingleUser.wins} multiplayer pobjeda`
    }

    const renderUser = () => {
        return (
            <div className="p-right-20 w100">
                <div className='w100 padding20 margin-bottom-20 single-user-wrap margin-right'>
                    <div>
                        <div className="profile-header">
                            <div className="single-user-profile-image-wrap">
                                <img className="profile-image-single margin-top-m" src={currSingleUser.avatar} />
                                {currUser !== currSingleUser.name && <button onClick={handlePMUser} className="content-button margin-top margin-bottom">Poruka</button>}
                            </div>
                            <div className="single-user-profile-content relative">
                                {currSingleUser.status === 1 && <p className="absolute-top-right form-p mediumaquamarine">Online</p>}
                                <h2 className="blue f16 margin-top-s">{currSingleUser.name}</h2>
                                <p className="margin-bottom-5"><span><strong>Level: </strong></span>{currSingleUser.level}</p>
                                {currUser === currSingleUser.name && <p className="f12">{generateNextLevelIn()}</p>}
                                {currSingleUser.level !== 0 && <p className="f12">{`Pobjeda/poraza: ${currSingleUser.wins}/${currSingleUser.loses} (${(currSingleUser.wins / currSingleUser.loses).toFixed(2)})`}</p>}
                                {currSingleUser.statusMessage && <p className="fw200 margin-top f12"><span><strong>Status:</strong></span> {currSingleUser.statusMessage}</p>}
                                <p className="margin-bottom-5 margin-top-m"><span><strong>Lokacija:</strong></span> {currSingleUser.location}</p>
                                <p className="margin-bottom-5"><span><strong>Godine:</strong></span> {getAgeFromDate(currSingleUser.birthDate)}</p>
                                <p className="margin-bottom-5"><span><strong>Spol:</strong></span> {currSingleUser.gender}</p>
                                <p className="margin-bottom-5"><span><strong>Član od:</strong></span> {formatDateToNormal(currSingleUser.createdAt)}</p>
                                <p className="fw200 faded f12 margin-top margin-bottom">{adminInfoMsg}</p>
                                <div>

                                    {passVisibility && <div className="relative margin-negative-btm-15">
                                        <input type={showPassword ? "text" : "password"}
                                            minLength={8}
                                            name="pass"
                                            id='pass'
                                            value={pass}
                                            placeholder="Unesite lozinku"
                                            onChange={handlePassInputChange}
                                            style={{ borderColor: borderColPass }}
                                        />
                                        {labelPass.length !== 0 && <label className="faded f12" htmlFor="email">{labelPass}</label>}
                                        <img
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "15px",

                                                cursor: "pointer"
                                            }}
                                            onClick={togglePasswordVisibility}
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVR4nJ3Svy6EURAF8C8kCsUWbGFlKyRsRzYr0fjT0FJYFQ3xChIegMQqSNjQbSXBG9DgUehUKlH95MZI1pcl1k0mc2fOOXfu3LlZlmUZihjpwopZCBfQwkEX1sJ8Em+jmnWxUE26b2L05UgdY3kxKnhDPQhzeMdsxGuBVzqJCzjDeJCH0Uw+4onACx2v/Z+et1DLgWOR3wk/msNr2Py63hH2MI1rPGE1ZlrHM64wgwZOUWo/bSV62scklhIxsKnIN7Gc72ERh7G/CP+C1/QpcvlG4reLy7jBYCKhB48+1z16cR544pXz1Uu4xDF20Z9mHD69x0ngQz+NIFXYiKp3qRXc4gHrCf/rLNNBA78JPgDKVfDOuhH8XgAAAABJRU5ErkJggg==" alt="show-password" />

                                        <img
                                            onClick={generatePassword}
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVR4nK3SsUtCURgF8Iegk40RBA3RpmCQoLS0tTREQ1vYFDa15CIuLo1CS4pN0doSBO39Z7+4eB881J7vYR+c5d5z7jn33Jsk2w4qeMbrBkxRWxYf4xFXATkmPZwvL3ZwlzrkiM/wiYPS4jDoo/1vzi0McBGQI75dd+fQ9qRA2y+oJmUHXVzjqIxoH+94wA0+sJcl1PD0R9SflGwRe6XtBt6wi2aIlsbDPMObr4jjxiVmsbjwDQOC8zdOI2eEe5wUvXMV4xh5iC/sFC4tHlLHYfapfgECT2rIOllQlwAAAABJRU5ErkJggg=="
                                            style={{
                                                position: "absolute",
                                                right: "35px",
                                                top: "15px",

                                                cursor: "pointer"
                                            }}
                                            alt="lock-2" />
                                    </div>}

                                    {imgVisibility && <div className="left column">
                                        <div className="w100">
                                            <input
                                                type="url"
                                                style={{ borderColor: inputColors.avatar }}
                                                value={currSingleUser.avatar}
                                                onChange={handleImageChange}
                                                className="mm-input w100"
                                                id="Slika"
                                                name="Slika"
                                                placeholder='Unesi link na sliku.'
                                            />
                                            {/* <label className="fw200 faded f12 margin-top-s" htmlFor="Slika">{inputLabels.avatar}</label> */}
                                            <a className="white form-p margin-top-5" target="_blank" href="https://postimg.cc/">Uplodiraj sliku (nakon uploada na PostImg servisu odaberite direktni link)</a>

                                        </div>

                                    </div>}

                                    {inputVisibility && <div className="margin-bottom left column">
                                        <div className="w100">
                                            <input
                                                style={{ borderColor: email.color }}
                                                value={email.value}
                                                onChange={handleEmailChange}
                                                className="mm-input w100"
                                                id="email"
                                                name="email"
                                                placeholder='Unesi email'
                                            />
                                            <label className="fw200 faded f12 margin-top-s" htmlFor="email">{email.label}</label>
                                        </div>

                                    </div>}

                                    {currUser === currSingleUser.name && <div className="row middle margin-top">
                                        {imgVisibility && <button disabled={inputColors.avatar !== 'MediumAquaMarine'} onClick={updateUserImg} className="margin-right content-button">Promjeni</button>}
                                        {!passVisibility && !inputVisibility && <p onClick={handleToggleImgInput} className="form-p underline pointer"><span>{imgVisibility ? 'Odustani' : 'Promjeni avatar'}</span></p>}
                                    </div>}

                                    {currUser === currSingleUser.name && <div className="row middle margin-top">
                                        {inputVisibility && <button disabled={email.color !== 'MediumAquaMarine'} onClick={updateUserEmail} className="margin-right content-button">Promjeni</button>}
                                        {!passVisibility && !imgVisibility && <p onClick={handleToggleEmailInput} className="form-p underline pointer"><span>{inputVisibility ? 'Odustani' : 'Promjeni email'}</span></p>}
                                    </div>}

                                    {currUser === currSingleUser.name && <div className="margin-top row middle margin-bottom">
                                        {passVisibility && <button disabled={borderColPass !== 'MediumAquaMarine'} onClick={resendLostPassMail} className="content-button margin-right">Promjeni</button>}
                                        {!inputVisibility && !imgVisibility && <p onClick={handleTogglePassInput} className={`form-p underline pointer`}><span>{`${!passVisibility ? 'Promjeni lozinku' : 'Odustani'}`}</span></p>}
                                    </div>}



                                </div>

                            </div>
                        </div>
                    </div>
                    <div>
                        {currSingleUser.galerija.length !== 0 && currUser === currSingleUser.name && <div className="margin-bottom margin-top row multiline space-between">
                            {currSingleUser.galerija.map(foto => {
                                return (
                                    <div key={`${Math.random()}${foto}`} className="relative user-gal-wrap">
                                        <span onClick={() => handleRemoveFoto(foto)} className="notification-number logout-ico pointer">x</span>
                                        <img className="user-gal-item" src={foto} />
                                    </div>
                                )
                            })}
                        </div>}
                        {currSingleUser.galerija.length !== 0 && currUser !== currSingleUser.name && <div className="margin-bottom">
                            <div className="w100 row right margin-bottom">
                                <div className="row middle">
                                    <img onClick={handlePrevImg} className="pointer margin-right-5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nLXUTQ6CMBCGYdcocAt0785jKMaLSIx6EqPH0GOIXsOfuNKtrxlTkwqFFtAv6Qbok07LtNWyBOgAPTXatu+NAUJgCRzIR54tgMAVmwBX7LkAYxs2A5645wkkZSurgulobNozlzLLyg91UA7AlBuwcUQXOngswAbAzhFMP5hfgm2ptpdtAaPMizvQB4bAqmSsgUdmbvQX0P9pyZKCFqt6KPs3pkDpzaa/zVwHpewT9XPOXRbS6A1ab/SFaWhS43KYGjENjVUJLmWaV2ZAA3VQqQHaywHIvjthBtwDump4tgkvFTh48uLgnDMAAAAASUVORK5CYII=" />
                                    <img onClick={handleNextImg} className="margin-left-5 pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVR4nK2UTQ6CMBQGWaPgLdC9O4+hGC8iMeJJjB5DjwF6DX/iSreMKalJlZYWcJJuaJn06+ur51kA+sBIjp5tvRZgAGyAE1XEtxQIXWUL4I6dGzC3yVZAgTsFkNTtrIlMlca6M3OJWRd/oApFAVzYAw/DXKoKz47CIzAxSPOPLKAZB4O0KO8pEP1MPIEdsK0ZU2As16pEOuGrqzDgn5EFhhZrWpSslEmh6M2u12atCkXsC+25Vh4L0egdWm/2JVOkSYvHYamVKdJYRnCJqd+ZRhrKQuUaUSYKIM7dSaaR+8BQDt/2wxuv6XjxvQimTgAAAABJRU5ErkJggg==" />
                                </div>
                            </div>
                            {currSingleUser.galerija.map((foto, index) => {
                                if (currGalFotoIndex === index) {
                                    return (
                                        <div key={`${Math.random()}${foto}`} className="user-gal-wrap-visitor">
                                            <img className="user-gal-item-visitor" src={foto} />
                                        </div>
                                    )
                                }
                            })}
                        </div>}
                        {renderNews()}
                        {renderSongs()}
                        {currUser === currSingleUser.name && <div className="form-item margin-top margin-bottom">
                            <input
                                type="url"
                                style={{ borderColor: inputColors.foto }}
                                value={currGalFoto}
                                onChange={handleFotoChange}
                                className="mm-input w100"
                                id="Foto"
                                name="Foto"
                                placeholder='Dodaj sliku u galeriju'
                            />
                            <label className="fw200 faded f12 margin-top-s" htmlFor="Foto">{inputLabels.foto}</label>
                            <a className="white form-p margin-top-5" target="_blank" href="https://postimg.cc/">Uplodiraj sliku (nakon uploada na PostImg servisu odaberite direktni link)</a>
                            <div className="row middle margin-top margin-bottom">
                                <button onClick={handleAddFoto} disabled={inputLabels.foto !== 'Bravo. Možeš dodati sliku.'} className="content-button">Dodaj</button>
                                <p className="form-p margin-left mediumaquamarine" >{infoMessage}</p>
                            </div>
                        </div>}
                        {currSingleUser.about && <p className="margin-top">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcklEQVR4nOXVu0pdQRTGce+nENE6HoKVsREEE0OiYIIxJE0ai6RS0JDCxiZ4IUG8iwGLpI1wiBgs7CSP4SNY2+cJfmFkFSJuPZ45FpINw8xa357vz2bNmt3Q8N89aEQZPWl9F4BJHGEdq7F+Vy/zThxg6gptBft4lgs4Rl+B3oq2AD2oFfIalUu5Mp5jCX/wDS8xW+O3nJtuhvFE1GEDnzAQ+iw+YzG34F+xU6A/xAk+5kDGcJaObYHehb8Yz4HM4TvaMYPuyHdH3B76XA7kBX6hFMYtkW+JuBT6aM2QMEwN+KhA68NaFiCMnmK+QFvEUDYkzH7gzaXc25SvCyAMm3GKw2jEw4ib6wVIzfgldX8U/HHMlciXc8yH4uTsoj/usabQmiLuDz299+S2gGVsoeNCbgS/8TPm4QtaB7bTvmoBHzB9jd56jZYa9H01kL24wl/FTZzm3hg35dK+vWogqaALGaNSDST9wwczRs+NkHv7/AMYIls4mWn9YQAAAABJRU5ErkJggg==" />
                                <strong>O meni:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.about}
                            </span>
                        </p>}
                        {currSingleUser.requests && <p className="margin-top">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB20lEQVR4nOXVzYuPURQH8MdbXmamScrLRmbGQorBSGrESrKwNDIrGxYWNvIyNQoLf4Kk2HjLkoQs/AdSQzJWyktDKTY0LD66Ounxe7nPzUo5m+e5536/95x77rnfW1X/haEXB3EVzzCDH/GdwpWY7/3bxc/jI+7gKDZiOeZjBYbDfzdw59BTGmAE07iGoULOWlwP3kgTeDSyGivb85+GA8EfzWXzAbs7AqpfmB6sR18GsyfWaa8CHuF4hnwB3/EWX3Emgz2Jh63OnXiRDjVThhkMxngLZrvtOprjJXbUnZdxIpPZjdTGLb6U1NkM5xQu1R3PU4tmCH1YXBsPx30Zz3ASZqru+IL+boQW8hDepSwxJ4Prx+e64xuWFgRYEGW6h3kN2LYgrxovUfW7QWYLE9rUWq6kQRMFxA2YbMIF9jQu1h278LpbC9dwq7G/sKzTbTcfD5IoNpDHoSDIBO53mkgK+wb7GtR5sCHA3ri4A90A2/A+SXhTtpmdJoHc3gQciDY9kgXWDOtwO3ibqxKLt+RQ/M+N128rlsWhrgr9OhZnmcozWVeFpgCLgrQSC3ErZOcJPoUSp5I+Dc0bw5KixVuE7TEORxveLH5Wq7IAa5IUhDalGnd+4f5F+wkc9JBTBGMHyQAAAABJRU5ErkJggg==" />
                                <strong>Zahtjevi/što tražiš:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.requests}
                            </span>
                        </p>}
                        {currSingleUser.hobiji && <p className="margin-top">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0klEQVR4nM3VT4iOURTH8WFkZiMGC5TNxGSUlRWaLCyIRMmUIsqaEUrKwk6alL9FJmUjEbGxsZA/USwkf1ajKERkhp2Uj07O4vY09b7e91FuPfXcc+693/uc8zvn6ej43wbW4xXGcBHT6wYswgesQA9GAlQ3ZAjHi3mAxuqGDOJ2MR/Ay7ohU3AvQDiJj5Gjdg+dhGP4FmHCXmzETuyOHFXW32oFcgQP0evPOI2b+JTwzsp6fwvYj+eYiSUYLXzduJ5Pd9qm5kW2YXIzgAjHa8wrVDVSWdOJc5mjCOMbXMHjEEgjwOJM6ILCdgNbi3kcegdzcQhn0Zdf/BTrGkHigBOVG3/F/ML2AKfwNmXcj8v4gQ2t1MLqSj5m4F0qbzme5LMd3xsCJqiFCMM4zhf+TXHrCfZNaxpSgAZTYUdxqfANZPiWtQWpbF6JXzhQ2DbjC5bWBRnGmayZC+hK+5Ysyt46IKOpnjX4ibtRoMUFDrYFwdr4QeV7iGBXSvdZ2vbhcMsQzMZ7rMKc/FnNKntUHZCrEY58vxYHFr72IdnOI9FdmeBHZcdVE2Qom11fVnd/xT+Ohdnu97QKifZ+H5+xYwJ/dOmQb3SGnrTFV79oGvIvxm9pNQACXDzAUQAAAABJRU5ErkJggg==" />
                                <strong>Hobiji:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.hobiji}
                            </span>
                        </p>}
                        {currSingleUser.muzika && <p className="margin-top p-bottom-10">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJ0lEQVR4nNXUzYvOURQH8MdbI5HGGKG8RU2yGEIWbEhemgULmfkDvGShQXmZLChESIrshCw0yh8gYmM0LCikKGFIshnFZCz00R3nye3X8zw2zzQ5devec84933O/95xTKo2UYBEu4Yc/8hid9QZZiDs4Guck2+oNsiUC5yAvhoOuBwWQzuGg67S/8qTudGVgQ5SVX/R/gWAiNuFQBnI30ZX+qh4AK/AZ93AhA0lFcDlVGFowF2fxHN9jPcMZzPkXyEtsrUUX9uILjmExJsRaguNh21MNYBR+pQvVQHAYTzGrRqKzw6ermsNrtFYAORKZfsK0mnT8uTc9fFsrGW9gewEkjZcOXMsbEk0V7jcVaL1SCeQi9sd+XdaMM/Eh/1ScRGN2bky67DwP74sA49GHZXHuzEDGYRANYduJAfSm7DEVj0K3I3wa0hQvghzArfzp6IlqmYK3mB+2r4lvdKdKCmq6Q9cfPgvwJgeYFP3RUuUvk8/5VLaxf4ib8fJE6/rYJ11P+JzAuTxAO25XAwif5viX1fHK1KDtmb0jdOnVa8K3OQ/QlTq4EHQVDpZX6JYn3mPfFr1VLvG0bwvbQPlv84C7cL2gW4tT5ZXpU4Yrs3PKvreQXF8lKlK59aeZVIuyJNgY/CsVJCox2TYUbaWsZD9iN5ZiBsaEbXJ08eaYxqPxM/Gf3U9/MVgxeAEoTeGreIVvWZ+kgO9wH/swNoZh0uc+Q9U3IvIbMJ6Iwp8QY0oAAAAASUVORK5CYII=" />
                                <strong>Omiljena muzika:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.muzika}
                            </span>
                        </p>}
                        {currSingleUser.filmovi && <p className="margin-top">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXElEQVR4nO3VzSpFURQHcPkohooBE57gTm4JZeBjQsJEMVMKZUAmDBSPYMgrmCgfRRQSyuejeISfTq1bB/fce8mNwV31b6+99l7rf9Zae+9TV1eTfykYxwnOfxEnGEuT3KMDrb+IDtylSR4xXQU8pkmesFAFPH0oV5V6ff9lghYc4DrVwAu8ZTT3LdYL8+vwby5FMo/VlD2HZdxkfOlNrOdStrUkTimSzeQ4h76IY8yVIZmLfQup67BZlgRNeMYQXiNYAw6jLMlYH/YXDMbhafwOSTuOsI3dQiaYiKM5kcokWd+K/W0Vk4R+hQEsRbB6rGA9xkImi7HvstJybWAy9O6o9UOZnjxEFl1hm0rilCLpw2mM+UBP9CVfBK+xXpgnfmfozSQJfQQ72PsBdjBcLG7mjccs9ivATIb/RxJ0FnlJb9GfUap8oD/2ffbt/PwKj1XpfzJaLMOa/L28A2pAP2q7nX28AAAAAElFTkSuQmCC" />
                                <strong>Omiljeni filmovi:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.filmovi}
                            </span>
                        </p>}
                        {currSingleUser.serije && <p className="margin-top">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIklEQVR4nO3UPytFYQDH8YPrTzGQQVd3YlFmg5LJYPQOmHQX8RIwGbwAGZRS6u53ksUmFiVENxNlEpPto1OnnG73dp9z3dM1+NVvOc/zfL/nec6fKPpPg2AiyivowQ6eUMi6eCRgziBOcIepdu7wCtvobTI+jgucYyyzIIGUcIkqRuvGpvGAIwy0JUjBhhLQI2aTa/N4i3eZBVSIz7NFd/GOA3xgI2DNz0uAMj5Ra9FXfOElYG7MK6clWzj81bnWJebF3IYSrGIvQ5dTnL74mwmR3OI4UFBFJcU5w0qoZC4KCNa6IRlGf66SdPLcySIm/8wzucE9rgP6jNMUZx8LIZIZLGVoqckum0s6FQ0km8n/ptLB1mJuWlLEeg4tdvJ0up9vjUt1snhAt7gAAAAASUVORK5CYII=" />
                                <strong>Omiljene serije:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.serije}
                            </span>
                        </p>}
                        {currSingleUser.knjige && <p className="margin-top">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAUlEQVR4nO3UMUoDQRTG8VWsRLcQrETiBYKKpSgb2N7GK1hYaBeCjShYWNik08IbeAMhV/uJOpFhScxMsoJFPviY2WGW/3tv5k1RLLWIsIUe6gQfY20ewDARUOMM97mQ05DFScY/d7mQKng3WitxhcEUvze+z1MhP5l8lgPb2EwM9BLdFEjVLEe8lhLovJCdMHYnXIBOW5Aq2lM2IeMStwYpvseDAOhNCGJhSDmlZzqtQWbpX0Fu8RI11+tfQG6wgg0c4altyB76OIwOdNgqJHokH/AYPIrmgwS/Yb/IEZ6xGubj3vjNX2eYC7nGRZTNLI9SH9MmKCWDOvJ6NmSpIugDzHe/Z3rxiYUAAAAASUVORK5CYII=" />
                                <strong>Omiljene knjige:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.knjige}
                            </span>
                        </p>}
                        {currSingleUser.citati && <p className="margin-top p-bottom-10">
                            <div className="row margin-bottom-10 middle">
                                <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVR4nOXVz4tPYRQG8JvIr4kpZcjGxMLKDKNJjVhJFrOZxYiVDQsLGzGUMvwRkhqbEc3sWBCL+Q8mNSZhpUQzSlgp1EdvjtzvnXvv95rvLJSzubfnnOec9z3v+z4ny/4LQxdO4g6eYwHf4zuHifB3LTf5DXzAA5zDXmzFavSgL/CHEXcdG5sWGMArTGJXQ85u3A3eQLvgoVjVaLM9txpOBH+objWLOJp1YDgWeZZ2AU9xoZMCvw1jeFIED+NFOtQWxy/foSCNpf8qrMBJl+Nliw+3cbEYHL5xf2y8CivhpQXcygPz6YqucJG+9JbywBdsWuEim1PePPAV3WXB4d8RCc/XYQVONz7lgdd1jwiXImF/HVbg9BfblTToSkXwCH5gug4r4V3GzTxwBG8qrvC1kIz1dViBsyYkpvXl43ESxRLCEtHTRghTV/CozJEU9i2Gsw4Mx2MU9FYFDOJ9kvBlFjgVAnmwXWBvSMzZv0i+B1PB29eUlGbJ6fhfFdPvALbEoW7H/vRG4ixTe65WXYSyAuuCtA1rcT9kZxYf8S1a+iw0bxQbmu46L2wzOBPX8F7jsZo1K7ATn/Euelw+4f5F+wmmYM9huVBdpgAAAABJRU5ErkJggg==" />
                                <strong>Omiljeni citati:</strong>
                            </div>
                            <span className="block">
                                {currSingleUser.citati}
                            </span>
                        </p>}
                    </div>
                </div>
            </div>
        )
    }

    const renderUserForm = () => {
        return (
            <div className="margin-top padding20 margin-right">
                <h3 className="margin-bottom">Uredi profil</h3>
                <div>
                    <div className="form-item margin-bottom">
                        <select style={{ borderColor: inputColors.location }} onChange={handleTownsChange} value={currSingleUser.location} className="mm-input" id="towns" name="towns">
                            <option disabled selected key="N" value="">U mjestu:</option>
                            {towns.map(town => <option key={town} value={town}>{town}</option>)}
                        </select>
                        <label className="fw200 faded f12 margin-top-s" htmlFor="towns">{inputLabels.location}</label>
                    </div>

                    <div className="form-item margin-bottom ">
                        <input
                            style={{ borderColor: inputColors.statusMessage }}
                            value={currSingleUser.statusMessage}
                            maxLength={maxLengths.statusMessage}
                            minLength={minLenghts.statusMessage}
                            onChange={handleStatusChange}
                            className="mm-input w100"
                            id="Status"
                            name="Status"
                            placeholder='Unesi status'
                        />
                        {inputLabels.statusMessage.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Status">{inputLabels.statusMessage}</label>}
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.about }}
                            value={currSingleUser.about}
                            maxLength={maxLengths.about}
                            minLength={minLenghts.about}
                            onChange={handleAboutChange}
                            className="mm-input w100"
                            id="about"
                            name="about"
                            placeholder='Unesi nešto o sebi'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="about">{inputLabels.about}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.requests }}
                            value={currSingleUser.requests}
                            maxLength={maxLengths.requests}
                            minLength={minLenghts.requests}
                            onChange={handleRequestChange}
                            className="mm-input w100"
                            id="requests"
                            name="requests"
                            placeholder='Unesi svoje zahtjeve, što tražiš'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="requests">{inputLabels.requests}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.hobiji }}
                            value={currSingleUser.hobiji}
                            maxLength={maxLengths.hobiji}
                            minLength={minLenghts.hobiji}
                            onChange={handleHobijiChange}
                            className="mm-input w100"
                            id="hobiji"
                            name="hobiji"
                            placeholder='Unesi svoje hobije'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="hobiji">{inputLabels.hobiji}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.muzika }}
                            value={currSingleUser.muzika}
                            maxLength={maxLengths.muzika}
                            minLength={minLenghts.muzika}
                            onChange={handleMuzikaChange}
                            className="mm-input w100"
                            id="muzika"
                            name="muzika"
                            placeholder='Unesi omiljene pjesme/bendove'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="muzika">{inputLabels.muzika}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.filmovi }}
                            value={currSingleUser.filmovi}
                            maxLength={maxLengths.filmovi}
                            minLength={minLenghts.filmovi}
                            onChange={handleFilmoviChange}
                            className="mm-input w100"
                            id="filmovi"
                            name="filmovi"
                            placeholder='Omiljeni filmovi'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="filmovi">{inputLabels.filmovi}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.serije }}
                            value={currSingleUser.serije}
                            maxLength={maxLengths.serije}
                            minLength={minLenghts.serije}
                            onChange={handleSerijeChange}
                            className="mm-input w100"
                            id="serije"
                            name="serije"
                            placeholder='Omiljene serije'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="serije">{inputLabels.serije}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.knjige }}
                            value={currSingleUser.knjige}
                            maxLength={maxLengths.knjige}
                            minLength={minLenghts.knjige}
                            onChange={handleKnjigeChange}
                            className="mm-input w100"
                            id="knjige"
                            name="knjige"
                            placeholder='Omiljene knjige'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="knjige">{inputLabels.knjige}</label>
                    </div>

                    <div className="form-item margin-bottom">
                        <textarea
                            style={{ borderColor: inputColors.citati }}
                            value={currSingleUser.citati}
                            maxLength={maxLengths.citati}
                            minLength={minLenghts.citati}
                            onChange={handleCitatiChange}
                            className="mm-input w100"
                            id="citati"
                            name="citati"
                            placeholder='Omiljeni citati'
                        />
                        <label className="fw200 faded f12 margin-top-s" htmlFor="citati">{inputLabels.citati}</label>
                    </div>

                    <div className="margin-top margin-bottom checkbox-wrap">
                        <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={checkBox}
                            onChange={handleSetCheckbox}
                        />
                        <label htmlFor="checkbox" className="fw200 margin-left form-p noselect">Pročitao sam <span onClick={handleOpenTermOfUse}>uvodnik autora</span></label>
                    </div>



                    <div className="row middle margin-bottom">
                        <button disabled={checkIsBtnDisabled()} onClick={updateUser} className="content-button">Ažuriraj profil</button>
                        <p className="form-p margin-left mediumaquamarine" >{infoMessage}</p>
                    </div>
                    {currUser !== 'TheCreator' && currUser !== 'Superadmin' && <div className="w100 right row">
                        <p className="fw200 faded f12 margin-negative-btm">{adminInfoMsgBottom}</p>
                        {adminInfoMsgBottom.length === 0 && <p onClick={handleDeleteUser} className="form-p underline pointer margin-top"><span>Trajno obriši profil</span></p>}
                    </div>}

                    {currUser === 'TheCreator' || currUser === 'Superadmin' ? (
                        <div className="column">
                            <div className="margin-top margin-bottom row">
                                <p onClick={handleStartKickingUser} className="form-p underline pointer"><span>Kick user</span></p>
                                <p onClick={startDeletingUser} className="form-p underline pointer margin-left"><span>Delete user</span></p>
                            </div>
                            <div className="row middle">
                                <input
                                    className="margin-right"
                                    style={{ width: '40px' }}
                                    type="number"
                                    value={suspensionDays}
                                    min={1}
                                    name="suspensionNum"
                                    id='suspensionNum'
                                    placeholder="Dana suspenzije"
                                    onChange={handleSuspensionDaysChange}
                                />
                                <p onClick={handleStartUserSuspension} className="form-p underline pointer"><span>Suspend user</span></p>
                            </div>
                        </div>


                    ) : null}

                </div>
            </div>
        )
    }

    if (!currSingleUser) {
        return
    }

    const checkUserFormVisibility = () => {
        if (currUser === currSingleUser.name) {
            return true
        } else if (currUser === 'TheCreator') {
            return true
        } else if (currUser === 'Superadmin') {
            return true
        } else {
            return false
        }
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        <p onClick={goBack} className="form-p underline pointer"><span>Pogledaj sve korisnike</span></p>
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderUser()}
                    {checkUserFormVisibility() && renderUserForm()}
                </div>

            </div>
        </div>
    )
}

export default SingleUser