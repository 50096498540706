import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from 'axios'
const env = process.env

const PayPalBtn = ({ socket }) => {

    const [resultMessage, setResultMessage] = React.useState(null)
    
    const initialOptions = {
        clientId: env.REACT_APP_PPCLIENTID,
        currency: "EUR",
    }

    const getAproveString = (data) => {
        if (env.REACT_APP_NODE_ENV === 'development') {
            return `http://localhost:4000/api/orders/${data.orderID}/capture`
        } else {
            return `https://server.nethr.chat/api/orders/${data.orderID}/capture`
        }
    }

    const createOrder = async () => {
        
        try {
            const response = await axios.post(`${env.REACT_APP_NODE_ENV === 'development' ? env.REACT_APP_ORDERS_DEV : env.REACT_APP_ORDERS}`, {
                cart: [
                    {
                        quantity: 1,
                    },
                ],
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const orderData = response.data;

            if (orderData.id) {
                return orderData.id;
                
            } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error(error);
            resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
        }
    }

    const onApprove = async (data, actions) => {
        try {
            const response = await axios.post(getAproveString(data), {}, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const orderData = response.data;

            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you message

            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
            } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
            } else if (!orderData.purchase_units) {
                throw new Error(JSON.stringify(orderData));
            } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                    orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                    orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

                    if (transaction.status === 'COMPLETED') {
                        setResultMessage(
                            `Primljeno! Hvala ti na podršci!`,
                        );
                        socket.emit('updateDonators', {
                            donator: localStorage.getItem("userName"),
                            amount: parseFloat(transaction.amount.value)
                        })
                    } else {
                        setResultMessage(
                            `Problem. Status transakcije: ${transaction.status}. Broj transakcije: ${transaction.id}.`,
                        );
                    }
            }
        } catch (error) {
            console.error(error);
            setResultMessage(
                `Oprostite, Vaša transakcija ne može biti procesirana... ${error}`,
            );
        }
    }

    return (
        <div>
            {resultMessage && <p className="margin-bottom gay">{resultMessage}</p>}
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    createOrder={createOrder}
                    onApprove={(data, actions) => { onApprove(data, actions) }}
                />
            </PayPalScriptProvider>
        </div>
    )
}

export default PayPalBtn