import React from 'react'
import axios from 'axios'
import Box from './Box'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env

const SingleNews = ({
    generateHeaderCloseIco,
    setType,
    currSingeNews,
    socket,
    me,
    currUser,
    generateCreatedAt,
    userSuspended
}) => {

    const [news, setNews] = React.useState({})

    const getSingleNews = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/news/${currSingeNews}`)

            if (response.data.status === 1) {
                setNews(response.data.news)
            }
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {      
    
        getSingleNews()
    }, [])

    const handleReturnToAllNews = () => {
        setType('news')
    }

    if (Object.keys(news).length === 0) {
        return
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        <div className="row">
                            <img className="margin-right pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJklEQVR4nK2TTU4CQRBGJ+wEZwweQj2MrlTCnh/j3hABr6NuSAhHQAmSaMQbPVPhw3SGbrod7aSSnpqql6+6qrIscoBD4EzWiMV7D3AEPAAr4AV4lL3KN7aYVFgbWAMdUxdQ3FVMKwa7ByYppQk8BQb7lBmsllTKJqcm6LXvzT6rPDqQq/zCdVoDOp7gY+A2AdoDRq5jVW4A0ASWwHmiyjf3Yx6AXcRg26ORatjlBHhyfhTAux67v8dsrA6cvGdj+YCm+OMvwNy24d9KdpqSZ9WbUvw0RQ7bzW5gbHoJwD4wLA/2uqwy5bBR97WTa4uuRvx29WbAZShgIGieqGwG3MUCr1S+rdMOWKAblelXFkgaWeeAhc2pzO7mG1Z57y28DpzK6rGEb2WgARcffAAzAAAAAElFTkSuQmCC" />
                            <p onClick={handleReturnToAllNews} className="form-p underline pointer"><span>Sve vijesti</span></p>
                        </div>
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper p-right-20">
                    <div className="margin-top">
                    <Box news={news} showBtn={false} />
                    </div>
                    <ChatBody
                    generateCreatedAt={generateCreatedAt}
                    socket={socket} 
                    news={news}
                    room={`${news.author}${news.id}`}
                    isNews={true}
                    currUser={currUser}
                    me={me}
                    />
                </div>
            </div>
            <ChatFooter 
            socket={socket} 
            userSuspended={userSuspended}
            room={`${news.author}${news.id}`}
            isNews={true}
            me={me}
            newsId={news.id}
            />
        </div>
    )
}

export default SingleNews